import {Link as RouterLink, Outlet} from "react-router-dom";
import React from "react";
import {useRouteMatch} from "../routes";
import {Box, Divider, Stack, Tab, Tabs, Typography} from "@mui/material";
import {TabContext} from "@mui/lab";
import {TabOutletPanel} from "./IngredientDetailPage";

export function AdminPage() {
    // handle tabs
    const routeMatch = useRouteMatch([
        '/admin/',
        '/admin/nutritional-parameters',
    ]);
    const currentTab = routeMatch?.pattern?.path;

    return (
        <>
            <Box>
                <Typography variant={"h4"}>
                    Admin
                </Typography>
                <TabContext value={currentTab ?? "/admin/"}>
                    <Stack direction={"row"}>
                        <Tabs
                            value={currentTab}
                            aria-label="admin-tabs"
                            orientation="vertical"
                            variant="fullWidth"
                        >
                            <Tab
                                label="Start"
                                value="/admin/"
                                component={RouterLink}
                                to={""}
                            />
                            <Tab
                                label="Nutritional Parameters"
                                value="/admin/nutritional-parameters"
                                component={RouterLink}
                                to={"nutritional-parameters"}
                            />
                        </Tabs>
                        <Divider/>
                        <TabOutletPanel>
                            <Outlet/>
                        </TabOutletPanel>
                    </Stack>
                </TabContext>
            </Box>
        </>
    )
}