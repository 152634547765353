import React, {ReactNode} from "react";
import {Box, IconButton} from "@mui/material";
import {MoreHoriz} from "@mui/icons-material";
import {styled} from "@mui/material/styles";

const InlineListItem = styled('li')(({theme}) => ({
    margin: theme.spacing(0.5),
}));

export default function ChipArray(props: { children: ReactNode, maxItems?: number }) {
    const {
        children,
        maxItems = 8
    } = props

    const [expanded, setExpanded] = React.useState(false);
    const allItems = React.Children.toArray(children)

    const handleClickExpand = () => {
        setExpanded(true)
    }

    const renderItemsBefore = (allItems: string | any[]) => {
        return [
            ...allItems.slice(0, maxItems),
            <IconButton onClick={handleClickExpand} size={"small"}>
                <MoreHoriz/>
            </IconButton>
        ]
    }

    const items = expanded || (maxItems && allItems.length <= maxItems)
        ? allItems
        : renderItemsBefore(allItems)

    return <Box
        sx={{
            display: 'flex',
            flexWrap: 'wrap',
            listStyle: 'none',
        }}
        component="ul"
    >
        {items.map((item: ReactNode, index: number) => (
            <InlineListItem key={index}>
                {item}
            </InlineListItem>
        ))}
    </Box>

}