// @mui
import {Extension, Home, LunchDining, AdminPanelSettings, Settings, Assistant} from "@mui/icons-material";
import {apiUrl} from "../../../config";
import {SCOPES} from "../../../access/permission-maps";

// ----------------------------------------------------------------------
export const navConfig = [
    {
        title: 'Overview',
        path: '/overview',
        icon: <Home/>,
        scopes: [SCOPES.overviewCanView]
    },
    {
        title: 'Food Products',
        path: '/food-products',
        icon: <LunchDining/>,
        scopes: [SCOPES.productsCanCreate, SCOPES.productsCanDelete, SCOPES.productsCanView, SCOPES.productsCanDelete]
    },
    {
        title: 'Ingredients',
        path: '/ingredients',
        icon: <Extension/>,
        scopes: [SCOPES.ingredientsCanCreate, SCOPES.ingredientsCanDelete, SCOPES.ingredientsCanView, SCOPES.ingredientsCanDelete]
    },
    {
        title: 'Assistant',
        path: '/assistant',
        icon: <Assistant/>,
        scopes: [SCOPES.chatCanCreate],
    },
    {
        title: 'Admin Django',
        path: apiUrl + "admin/",
        icon: <AdminPanelSettings/>,
        scopes: [SCOPES.adminCanCreate, SCOPES.adminCanView, SCOPES.adminCanDelete, SCOPES.adminCanEdit],
    },
    {
        title: 'Admin',
        path: '/admin',
        icon: <Settings/>,
        scopes: [SCOPES.adminCanCreate, SCOPES.adminCanView, SCOPES.adminCanDelete, SCOPES.adminCanEdit],
    },
];

export const navCreateConfig = [
    {
        title: 'Create Food Product',
        path: '/food-products/new-food-product',
        icon: <LunchDining/>,
        scopes: [SCOPES.productsCanCreate]
    },
    {
        title: 'Create Ingredient',
        path: '/ingredients/new-ingredient',
        icon: <Extension/>,
        scopes: [SCOPES.ingredientsCanCreate]
    },
];

