import {styled} from "@mui/material/styles";
import MuiInput from "@mui/material/Input";
import React from "react";
import {Grid2, Slider, Typography} from "@mui/material";

const Input = styled(MuiInput)`
    width: 42px;
`;

export default function InputSlider(props: { label: React.ReactNode, onChange: (arg0: any) => void, value: string }) {

    const onChange = (value: number) => {
        props.onChange(value.toString())
    }

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        onChange(newValue as number);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value === '' ? 0 : Number(event.target.value));
    };

    const handleBlur = () => {
        if (Number(props.value) < 0) {
            onChange(0);
        } else if (Number(props.value) > 100) {
            onChange(100);
        }
    };

    function valuetext(value: number) {
        return `${value} %`;
    }

    return (
        <Grid2 container spacing={2} sx={{alignItems: 'center'}}>
            <Typography id="input-slider" gutterBottom>
                {props.label}
            </Typography>
            <Grid2 size={10}>
                <Slider
                    value={typeof Number(props.value) === 'number' ? Number(props.value) : 0}
                    onChange={handleSliderChange}
                    aria-labelledby="input-slider"
                    defaultValue={0.0}
                    getAriaValueText={valuetext}
                    step={0.1}
                    min={0.0}
                    max={100.0}
                    valueLabelDisplay="auto"
                />
            </Grid2>
            <Grid2>
                <Input
                    value={Number(props.value)}
                    size="small"
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    inputProps={{
                        step: 0.1,
                        min: 0.0,
                        max: 100.0,
                        type: 'number',
                        'aria-labelledby': 'input-slider',
                    }}
                /> %
            </Grid2>
        </Grid2>
    );
}