import React, {Fragment, useState} from "react";
// @mui
import {
    Avatar,
    Box,
    Card,
    CardActions,
    CardContent,
    Divider,
    Grid2,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Skeleton,
    Stack,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {ChatBubbleOutline, ContentCopy, SendOutlined, ThumbDownOutlined, ThumbUpOutlined,} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
// @azure/msal-react
import {useMsal} from "@azure/msal-react";
import openAPIGeneratorChatInstance from "../../openAPIGeneratorChatInstance";
import Markdown from 'react-markdown'
import {stringInitials} from "../../utils/userName";
import {fallBackUserName} from "../../config";
// utils
import remarkGfm from 'remark-gfm';
import {useQuery} from "@tanstack/react-query";
import DeleteIcon from "@mui/icons-material/Delete";
import {format} from "date-fns";
import Pagination from "@mui/material/Pagination";
import {Conversation, Message} from "../../api";
import {DataLoadingStatusHandler} from "../data-loading-status-handler";

//-------------------------------------------------------------------------------------------------------------------

// Query to fetch the list of conversations
export const conversationsQuery = (chatPage: number, pageSize: number) => ({
    queryKey: ['conversations', chatPage, pageSize],
    queryFn: () => {
        return openAPIGeneratorChatInstance
            .assistantConversationsList('-updated_at', chatPage, pageSize)
            .then(response => response.data)
    },
    initialData: {
        'count': 0,
        'next': null,
        'previous': null,
        'results': []
    },
    keepPreviousData: true
})

//-------------------------------------------------------------------------------------------------------------------

function AssistantMessage(props: { message: any; index: any }) {
    const responseFeedback = Array.isArray(props.message.feedback) && props.message.feedback.length > 0
        ? props.message.feedback[props.message.feedback.length - 1]
        : 0;
    const [goodResponse, setGoodResponse] = useState(responseFeedback === 1);
    const [badResponse, setBadResponse] = useState(responseFeedback === -1);
    const [copy, setCopy] = useState(false);

    const handleGoodResponse = async () => {
        setGoodResponse(true);
        setBadResponse(false);
        try {
            const response = await openAPIGeneratorChatInstance.assistantMessageFeedbackCreate({
                message: props.message.id,
                feedback: 1
            });
            if (response.status === 201) {
                console.log('Positive feedback recorded for the message:', props.message.id);
            }
        } catch (error) {
            console.error('Failed to record feedback for the message:', props.message.id);
        }
    };

    const handleBadResponse = async () => {
        setGoodResponse(false);
        setBadResponse(true);

        try {
            const response = await openAPIGeneratorChatInstance.assistantMessageFeedbackCreate({
                message: props.message.id,
                feedback: -1
            });
            if (response.status === 201) {
                console.log('Negative feedback recorded for the message:', props.message.id);
            }
        } catch (error) {
            console.error('Failed to record feedback for the message:', props.message.id);
        }
    };

    const handleCopy = () => {
        setCopy(true);
        navigator.clipboard.writeText(props.message.content);
    };

    return (
        <Fragment key={props.index}>
            <Grid2 size={1}/>
            <Grid2 size={11}>
                <Box sx={{maxWidth: '100%'}}>
                    <Card sx={{width: '100%'}}>
                        <CardContent>
                            {props.message.content === '...' ? (
                                <Skeleton animation="wave" height={40}/>
                            ) : (
                                <Typography
                                    variant="body2"
                                    sx={{
                                        wordWrap: 'break-word',
                                        overflowWrap: 'break-word',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'normal',
                                        overflowX: 'auto'
                                    }}>
                                    <Markdown remarkPlugins={[remarkGfm]}>
                                        {props.message.content}
                                    </Markdown>
                                </Typography>
                            )}
                        </CardContent>
                        <CardActions>
                            <Tooltip title="Good response">
                                <IconButton aria-label="good response" onClick={handleGoodResponse}>
                                    {goodResponse ? (
                                        <ThumbUpOutlined fontSize="small" color="primary"/>
                                    ) : (
                                        <ThumbUpOutlined fontSize="small"/>
                                    )}
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Bad response">
                                <IconButton aria-label="bad response" onClick={handleBadResponse}>
                                    {badResponse ? (
                                        <ThumbDownOutlined fontSize="small" color="primary"/>
                                    ) : (
                                        <ThumbDownOutlined fontSize="small"/>
                                    )}
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Copy">
                                <IconButton aria-label="copy" onClick={handleCopy} disabled={copy}>
                                    <ContentCopy fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        </CardActions>
                    </Card>
                </Box>
            </Grid2>
        </Fragment>
    );
}


export function UserMessage(props: { message: any; index: any; accounts: any }) {
    const theme = useTheme();
    const account = props.accounts[0];

    return (
        <Fragment key={props.index}>
            <Grid2 size={11}>
                <Box sx={{maxWidth: '100%'}}>
                    <Card sx={{width: '100%'}}>
                        <CardContent>
                            <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
                                <Avatar
                                    sx={{
                                        bgcolor: theme.palette.primary.main,
                                        width: 38,
                                        height: 38,
                                    }}
                                    aria-label="avatar"
                                >
                                    {stringInitials(account.name ?? fallBackUserName)}
                                </Avatar>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        wordWrap: 'break-word',
                                        overflowWrap: 'break-word',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'pre-wrap'
                                    }}>
                                    {props.message.content}
                                </Typography>
                            </Stack>
                        </CardContent>
                    </Card>
                </Box>
            </Grid2>
            <Grid2 size={1}/>
        </Fragment>
    );
}


export function PlaceHolderMessage() {
    return (
        <Grid2 size={12}>
            <Box sx={{maxWidth: '100%'}}>
                <Card sx={{width: '100%'}}>
                    <CardContent>
                        <Typography variant="body2">
                            Hi, I am <strong>Cibus</strong>.<br/>
                            Your food reformulation assistant.<br/>
                            I'm here to help, <strong>ask me anything</strong>.
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
        </Grid2>
    );
}


export function ChatField(props: { label: any; message: any; handleChange: any; handleSend: any }) {
    return <Grid2 size={12} style={{overflow: 'hidden'}}>
        <Divider sx={{my: 2}}/>
        <TextField
            fullWidth
            color="primary"
            multiline
            minRows={1}
            label={props.label}
            id="chat"
            value={props.message}
            onChange={props.handleChange}
            onKeyDown={(ev) => {
                if (ev.key === 'Enter' && !ev.shiftKey) {
                    ev.preventDefault();
                    props.handleSend();
                }
            }}
            slotProps={{
                input: {
                    startAdornment: (
                        <InputAdornment position="start">
                            <ChatBubbleOutline/>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton type="submit" onClick={props.handleSend} aria-label="send" edge="end">
                                <SendOutlined/>
                            </IconButton>
                        </InputAdornment>
                    ),
                    style: {
                        overflow: 'auto',
                        whiteSpace: 'pre-wrap',
                        textOverflow: 'break-word',
                    },
                }
            }}
        />
    </Grid2>
}


export function ChatBot(props: { conversation: any; message: any; handleChange: any; handleSend: any }) {
    const {accounts} = useMsal();

    return (
        <Grid2 container spacing={1} sx={{padding: 1}}>
            {props.conversation.length > 1 ? (
                <Fragment>
                    {props.conversation.map((message: any, index: any) => (
                        message.role === 'assistant' ? (
                            <AssistantMessage key={index} message={message} index={index}/>
                        ) : (
                            <UserMessage key={index} message={message} index={index} accounts={accounts}/>
                        )
                    ))}
                </Fragment>
            ) : (
                <PlaceHolderMessage/>
            )}
        </Grid2>
    );
}

export function ChatHistory(props: {
    onSelect?: () => void,
    handleConversationChange: (conversation: Conversation) => void,
    deleteConversation: (conversation: Conversation) => void
    maxEntries?: number,
}) {
    const [chatPage, setChatPage] = useState(1);
    const [pageSize] = useState(props.maxEntries ?? 5);

    const {data, status} = useQuery(
        conversationsQuery(chatPage, pageSize)
    )

    // Chat History Pagination handler
    const handlePageChange = (_: React.ChangeEvent<unknown>, newPage: number) => {
        setChatPage(newPage);
    };

    const handleConversationChange = (newValue: Conversation) => {
        props.handleConversationChange(newValue);
        if (props.onSelect) {
            props.onSelect()
        }
    }

    return <DataLoadingStatusHandler
        status={status}
        successRender={
            <>
                <List>
                    {data.results
                        .map((conversation, index) => (
                            <ListItem
                                key={conversation.id}
                                secondaryAction={
                                    <IconButton edge="end" aria-label="delete"
                                                onClick={() => props.deleteConversation(conversation)}>
                                        <DeleteIcon/>
                                    </IconButton>
                                }
                                disablePadding>
                                <ListItemButton
                                    onClick={() => handleConversationChange(conversation)}
                                >
                                    <ListItemText
                                        primary={`${conversation.title ?? "Unnamed"}`}
                                        secondary={`Updated: ${format(new Date(conversation.updated_at), "dd/MM/yyyy 'at' h:mm a")}`}/>
                                </ListItemButton>
                            </ListItem>
                        ))}
                </List>
                <Pagination
                    variant="outlined"
                    shape="rounded"
                    count={Math.ceil(data?.count / pageSize)}
                    page={chatPage}
                    onChange={handlePageChange}
                    sx={{mt: 2, mb: 2, display: 'flex', justifyContent: 'center'}}
                />
            </>
        }
    />
}

export type SimplifiedMessage = {
    id: Message["id"]
    role: Message["role"]
    content: Message["content"]
    timestamp: Message["timestamp"]
    feedback: Message["feedback"]
}


//----------------------------------------------------------------------------------------------------------------------