import React from 'react';
import {CSSObject, styled, Theme} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import throttle from 'lodash.throttle';
import {Box, IconButton, Tooltip, Typography} from '@mui/material';
import {toolConfig, TToolConfig} from "./config";
import {CloseOutlined, Warning} from "@mui/icons-material";

//------------------------------------------------------------------------------------------------

//TODO: tool window can be adjusted!

export const openedMixin = (theme: Theme, width: number): CSSObject => ({
    background: theme.palette.background.default,
    width: width,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    background: theme.palette.background.default,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

interface DrawerProps {
    open: boolean;
    width: number;
    theme?: Theme;
}

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})<DrawerProps>(
    ({theme, open, width}) => ({
        width: width,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        height: '100%',  // Ensure the drawer takes full height
        overflow: 'hidden',  // Prevent scrolling inside the drawer
        ...(open && {
            ...openedMixin(theme, width),
            '& .MuiDrawer-paper': {
                ...openedMixin(theme, width),
                overflow: 'hidden',  // Prevent scrolling inside the drawer
            },
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': {
                ...closedMixin(theme),
                overflow: 'hidden',  // Prevent scrolling inside the drawer
            },
        }),
    })
)

export default function ToolWindow() {
    const [open, setOpen] = React.useState<boolean>(false);
    const [activeTool, setActiveTool] = React.useState<TToolConfig | undefined>(undefined);
    const [drawerWidth, setDrawerWidth] = React.useState<number>(450);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setActiveTool(undefined);
        setOpen(false);
    };

    const handleResize = React.useCallback(
        throttle((event: MouseEvent) => {
            const newWidth = window.innerWidth - event.clientX;
            setDrawerWidth(Math.max(400, Math.min(newWidth, 600)));
        }, 100),
        []
    );

    // const SelectToolComponent = toolConfig.find((tool) => tool.title === activeTool?.title)?.tool
    const SelectToolComponent = activeTool?.tool

    return (
        <Drawer
            variant="permanent"
            open={open}
            anchor="right"
            width={drawerWidth}
        >
            <Toolbar/>
            <Stack direction="row" sx={{
                justifyContent: "end"
            }}>
                {open && (
                    <>
                        <div
                            style={{width: '5px', cursor: 'col-resize'}}
                            onMouseDown={(e) => {
                                e.preventDefault();
                                window.addEventListener('mousemove', handleResize);
                                window.addEventListener('mouseup', () => {
                                    window.removeEventListener('mousemove', handleResize);
                                }, {once: true});
                            }}
                        />
                        {SelectToolComponent ?
                            <SelectToolComponent handleDrawerOpen={handleDrawerOpen}
                                                 handleDrawerClose={handleDrawerClose}/>
                            : <Box sx={{width: '100%'}}>
                                <Box sx={{
                                    width: '100%',
                                    padding: 0.5,
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    justifyContent: 'flex-end'
                                }}>
                                    <IconButton onClick={handleDrawerClose} aria-label="close" sx={{ml: 1}}>
                                        <CloseOutlined/>
                                    </IconButton>
                                </Box>
                                <Box
                                    sx={{
                                        p: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        gap: 2
                                    }}>
                                    <Warning fontSize={"large"} color={"disabled"}/>
                                    <Typography variant={"h4"} color={"textDisabled"}>Tool Not Available</Typography>
                                </Box>
                            </Box>
                        }
                        <Divider orientation="vertical"/>
                    </>
                )}
                <List disablePadding>
                    {toolConfig.map((item) => (
                        <ListItem key={"Expand"} disablePadding sx={{display: 'block'}}>
                            <Tooltip title={item.title} followCursor>
                                <ListItemButton
                                    onClick={() => {
                                        if (item.title !== activeTool?.title) {
                                            setActiveTool(item)
                                            handleDrawerOpen()
                                        } else {
                                            handleDrawerClose()
                                        }
                                    }}
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 0 : 0,
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={""} sx={{opacity: open ? 1 : 0}}/>
                                </ListItemButton>
                            </Tooltip>
                        </ListItem>
                    ))}
                </List>
            </Stack>
        </Drawer>
    );
};
