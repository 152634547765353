// @mui
import {alpha, styled} from '@mui/material/styles';
import {AppBar, Box, Breadcrumbs, Link, Stack, Toolbar} from '@mui/material';
// components
import AccountPopover from './AccountPopover';
// routes
import {Link as RouterLink, useMatches} from "react-router-dom";
import Logo from "../../../components/logo";

// ----------------------------------------------------------------------
function bgBlur(props: any) {
    const color = props?.color || '#000000';
    const blur = props?.blur || 6;
    const opacity = props?.opacity || 0.8;
    const imgUrl = props?.imgUrl;

    if (imgUrl) {
        return {
            position: 'relative',
            backgroundImage: `url(${imgUrl})`,
            '&:before': {
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 9,
                content: '""',
                width: '100%',
                height: '100%',
                backdropFilter: `blur(${blur}px)`,
                WebkitBackdropFilter: `blur(${blur}px)`,
                backgroundColor: alpha(color, opacity),
            },
        };
    }

    return {
        backdropFilter: `blur(${blur}px)`,
        WebkitBackdropFilter: `blur(${blur}px)`,
        backgroundColor: alpha(color, opacity),
    };
}

const HEADER_MOBILE = 64;

const StyledRoot = styled(AppBar)(({theme}): any => ({
    ...bgBlur({color: theme.palette.background.default}),
    boxShadow: 'none',
}));

const StyledToolbar = styled(Toolbar)(({theme}): any => ({
    minHeight: HEADER_MOBILE,
    padding: theme.spacing(0, 5),
}));

function LinkRouter(props: any) {
    return <Link {...props} component={RouterLink}/>;
}

// ----------------------------------------------------------------------
export default function Header() {
    let matches = useMatches();
    let crumbs = matches
        // first get rid of any matches that don't have handle and crumb
        // @ts-ignore
        .filter((match) => Boolean(match.handle?.crumb))
        // now map them into an array of elements, passing the loader
        // data to each one
        // @ts-ignore
        .map((match) => match.handle.crumb(match));


    return (
        <StyledRoot
            position="fixed" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
            <StyledToolbar>
                <Breadcrumbs aria-label="breadcrumb">
                    <Logo key={"logo"}/>
                    {crumbs.map((crumb) =>
                        crumb
                    )}
                </Breadcrumbs>

                {/*<Breadcrumbs aria-label="breadcrumb">*/}
                {/*    <LinkRouter underline="hover" color="inherit" to="/">*/}
                {/*        <Logo/>*/}
                {/*    </LinkRouter>*/}
                {/*    {pathNames.map((value, index) => {*/}
                {/*        const last = index === pathNames.length - 1;*/}
                {/*        const to: any = `/${pathNames.slice(0, index + 1).join('/')}`;*/}

                {/*        if (last && to in breadcrumbsConfig) {*/}
                {/*            return (*/}
                {/*                <Typography color="textPrimary" key={to}>*/}
                {/*                    {breadcrumbsConfig[to]}*/}
                {/*                </Typography>*/}
                {/*            );*/}
                {/*        } else if (last && !(to in breadcrumbsConfig)) {*/}
                {/*            return (*/}
                {/*                <Typography color="textPrimary" key={to}>*/}
                {/*                    {pathNames[index]}*/}
                {/*                </Typography>*/}
                {/*            );*/}
                {/*        } else {*/}
                {/*            return (*/}
                {/*                <LinkRouter underline="hover" color="inherit" to={to} key={to}>*/}
                {/*                    {breadcrumbsConfig[to]}*/}
                {/*                </LinkRouter>*/}
                {/*            );*/}
                {/*        }*/}
                {/*    })}*/}
                {/*</Breadcrumbs>*/}
                <Box sx={{flexGrow: 1}}/>

                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={{
                        xs: 0.5,
                        sm: 1,
                    }}
                >
                    <AccountPopover/>
                </Stack>
            </StyledToolbar>
        </StyledRoot>
    );
}
