import {Helmet} from "react-helmet-async";
import {apiRoutes} from "../../../config";
import openAPIGeneratorFoodDesignerInstance from "../../../openAPIGeneratorFoodDesignerInstance";
import {Box, Button, IconButton, Tooltip} from "@mui/material";
import {MaterialReactTable, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import React, {useMemo, useState} from "react";
import {NutritionalParameter} from "../../../api";
import {useMutation, useQuery} from "@tanstack/react-query";
import MRTButtonStack from "../../../components/ingredient-buttons/IngredientButtons";
import {Clear, Edit} from "@mui/icons-material";
import {queryClient} from "../../../index";
import {capitalizedStr} from "../../../utils/formatText";

export const nutritionalParametersPageQuery = (page: number, pageSize: number, search: string, material_entity_id?: number) => ({
    queryKey: [apiRoutes.foodDesigner.nutritionalParameters.baseEndpoint, search, page, pageSize],
    queryFn: () => {
        return openAPIGeneratorFoodDesignerInstance.foodDesignerNutritionalParametersList(material_entity_id, undefined, page, pageSize, search)
            .then(response => response.data)
    },
})


export function NutritionalParametersPage() {
    const columns: MRT_ColumnDef<NutritionalParameter>[] = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                enableEditing: false,
                size: 100,
            },
            {
                accessorKey: 'name',
                header: 'Name',
                Cell: ({row, renderedCellValue}) => capitalizedStr(renderedCellValue?.toString() ?? ""),
                size: 100,
            },
            {
                accessorKey: 'description',
                header: 'Description',

                size: 100,
            },
            {
                accessorKey: 'default_unit',
                header: 'Default Unit',
                size: 100,
            },
            {
                accessorKey: 'eurofir_code',
                header: 'EUROFIR code',
                size: 100,
            },
            {
                accessorKey: 'caloric_multiplier',
                header: 'Caloric Multiplier',
                size: 100,
            },
            {
                accessorKey: 'parent_parameter',
                header: 'Parent ID',
                size: 100,
            },
        ],
        [],
    );
    const [searchQuery, setSearchQuery] = useState<string>(""); // Updated to string
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 100, //customize the default page size
    });

    const {
        data: nutritionalParametersData,
        status: queryStatus,
    } = useQuery(
        nutritionalParametersPageQuery(pagination.pageIndex + 1, pagination.pageSize, searchQuery)
    );

    //only root rows with no managerId

    const rootData = useMemo(() => nutritionalParametersData?.results.filter((r) => !r.parent_parameter), [nutritionalParametersData]);

    const nutritionalParameterUpdateMutation: any = useMutation({
        mutationFn: (content: NutritionalParameter) => {
            return openAPIGeneratorFoodDesignerInstance.foodDesignerNutritionalParametersUpdate(
                content.id,
                content
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [apiRoutes.foodDesigner.nutritionalParameters.baseEndpoint]})
        },
    })

    const nutritionalParameterCreateMutation: any = useMutation({
        mutationFn: (content: NutritionalParameter) => {
            return openAPIGeneratorFoodDesignerInstance.foodDesignerNutritionalParametersCreate(
                content
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [apiRoutes.foodDesigner.nutritionalParameters.baseEndpoint]})
        },
    })

    const nutritionalParameterDeleteMutation: any = useMutation({
        mutationFn: (id: number) => {
            return openAPIGeneratorFoodDesignerInstance.foodDesignerNutritionalParametersDestroy(id)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [apiRoutes.foodDesigner.nutritionalParameters.baseEndpoint]})
        },
    })

    const table = useMaterialReactTable({
        columns: columns,
        data: rootData ?? [],
        enableExpanding: true,
        getSubRows: (row) => nutritionalParametersData?.results.filter((r) => r.parent_parameter === row.id),

        initialState: {
            density: 'compact',
            expanded: true,
            columnVisibility: {description: false},
        },

        enableEditing: true,
        editDisplayMode: "modal",
        createDisplayMode: "modal",
        onEditingRowSave: ({table, row, values}) => {
            nutritionalParameterUpdateMutation.mutate({...row.original, ...values});
            table.setEditingRow(null); //exit editing mode
        },
        onCreatingRowSave: ({values}) => {
            nutritionalParameterCreateMutation.mutate(values);
            table.setCreatingRow(null); //exit creating mode
        },
        positionActionsColumn: 'last',
        renderRowActions: ({row}) => (
            <MRTButtonStack>
                <Tooltip title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Remove"}>
                    <IconButton onClick={() => nutritionalParameterDeleteMutation.mutate(row.original.id)}>
                        <Clear color={"error"}></Clear>
                    </IconButton>
                </Tooltip>
            </MRTButtonStack>
        ),
        renderTopToolbarCustomActions: ({table}) => (
            <Button
                onClick={() => {
                    table.setCreatingRow(true);
                }}
            >
                Create Nutritional Parameter
            </Button>
        ),

        // enablePagination: true,
        // manualPagination: true,
        manualFiltering: true, //turn off client-side filtering
        onGlobalFilterChange: setSearchQuery, //hoist internal global state to your state
        onPaginationChange: setPagination,
        state: {
            pagination: pagination,
            globalFilter: searchQuery,
        }, //pass in your own managed globalFilter state
        // rowCount: nutritionalParametersData?.count ?? 0
    })


    return <>
        <Helmet>
            <title> Admin - Nutritional Parameters | CibusAI </title>
        </Helmet>
        <Box>
            <MaterialReactTable table={table}/>
        </Box>
    </>
}