// @mui
import {
    Card,
    CardContent,
    Chip,
    CircularProgress,
    Grid2,
    List,
    ListItem,
    ListItemText,
    Stack,
    Typography
} from "@mui/material";
// components
import {IngredientHighlights} from "../../../components/ingredient-highlights";
import {annotationTypes} from "../../../config";
import {useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {ingredientDetailQuery} from "../../../pages/IngredientDetailPage";
import {AnnotationTypeBreakdown} from "../../../components/insights/AnnotationTypeBreakdown";
import React from "react";
import {ingredientInsightsQuery} from "../foods/FoodInsightsSection";
import {DataLoadingStatusHandler} from "../../../components/data-loading-status-handler";
import DataOperationStatus from "../../../components/data-operation-status/DataOperationStatus";

// ----------------------------------------------------------------------
function barChartFactory(title: string, value: any, mainColor: string, titleColor: string) {

    return {
        series: [{
            name: title,
            data: [value]
        }],
        options: {
            chart: {
                type: "bar",
                stacked: true,
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    barHeight: 16,
                    colors: {
                        backgroundBarColors: ["rgba(192,201,192,0.1)"]
                    }
                }
            },
            colors: ["#eaae17"],
            stroke: {
                width: 0
            },
            title: {
                floating: true,
                offsetX: -10,
                offsetY: 5,
                text: title,
                style: {
                    fontSize: "16px",
                    color: titleColor,
                    fontWeight: "lighter"
                }
            },
            subtitle: {
                floating: true,
                align: "right",
                offsetY: 0,
                text: `${value} %`,
                style: {
                    fontSize: "16px",
                    color: titleColor
                }
            },
            tooltip: {
                enabled: false
            },
            xaxis: {
                categories: [title]
            },
            yaxis: {
                max: 100
            },
            fill: {
                type: "gradient",
                gradient: {
                    inverseColors: false,
                    gradientToColors: [mainColor],
                }
            }
        }
    };
}

/*function ChartView(props: {ingredient: MaterialEntity}) {
    const theme = useTheme();
    //console.log(props.ingredient)

    const tasteReactApexChart: any = useMemo(() => {
        const tasteValues = [
            props.ingredient.insights.sweetness,
            props.ingredient.insights.bitterness,
            props.ingredient.insights.sourness,
            props.ingredient.insights.umami,
            props.ingredient.insights.tasteless
        ];

        const tasteLabels = [
            'sweetness',
            'bitterness',
            'sourness',
            'umami',
            'tasteless'
        ];

        return {
            series: [{
                name: 'Likelihood',
                data: tasteValues,
                color: theme.palette.primary.main
            }],
            options: {
                chart: {
                    width: '100%',
                    type: 'radar',
                    toolbar: {
                        show: false
                    }
                },
                xaxis: {
                    categories: tasteLabels,
                    labels: {
                        style: {
                            colors: theme.palette.text.secondary,
                            fontSize: 14
                        }
                    }
                },
                yaxis: {
                    show: false
                }
            }
        };
        }, [props.ingredient, theme.palette.primary.main, theme.palette.text.secondary]
    )

    const textureReactApexChart: any = useMemo(
        () => {
            const textureValues = [
                props.ingredient.insights.viscosity,
                props.ingredient.insights.gelation,
                props.ingredient.insights.waterBinding,
                props.ingredient.insights.emulsification,
                props.ingredient.insights.foaming,
                props.ingredient.insights.solubility,
                props.ingredient.insights.thermostability,
            ]
            const textureLabels = [
                'viscosity',
                'gelation',
                'water binding',
                'emulsification',
                'foaming',
                'solubility',
                'thermostability'
            ]
            return {
                series: [{
                    name: 'Likelihood',
                    data: textureValues,
                    color: theme.palette.warning.main
                }],
                options: {
                    chart: {
                        width: '100%',
                        type: 'line',
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    xaxis: {
                        categories: textureLabels,
                        labels: {
                            style: {
                                colors: theme.palette.text.secondary,
                                fontSize: 14
                            }
                        }
                    }
                }
            }
        },
        [props.ingredient, theme.palette.warning.main, theme.palette.text.secondary]
    )

    const aromaAndOdorReactApexChart: any = useMemo(
        () => {
            const aromaAndOdorValues = [].map(() => faker.number.int({min: 0, max: 130}))
            const aromaAndOdorLabels = [].map((aromaAndOdor: any) => aromaAndOdor)
            return {
                series: [{
                    name: 'Likelihood',
                    data: aromaAndOdorValues,
                    color: theme.palette.success.main
                }],
                options: {
                    chart: {
                        width: '100%',
                        type: 'bar',
                        toolbar: {
                            show: false
                        }
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 4,
                            horizontal: true,
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    xaxis: {
                        categories: aromaAndOdorLabels,
                        labels: {
                            style: {
                                colors: theme.palette.text.secondary,
                                fontSize: 14
                            }
                        }
                    }
                }
            }
        },
        [props.ingredient, theme.palette.success.main, theme.palette.text.secondary]
    )

    const antihypertensiveReactApexChart: any = useMemo(
        () => {
            return barChartFactory(
                "Antihypertensive",
                props.ingredient.insights.antihypertensive,
                theme.palette.error.main,
                theme.palette.text.secondary
            )
        },
        [
            props.ingredient,
            theme.palette.error.main,
            theme.palette.text.secondary,
        ]
    )

    const antioxidantReactApexChart: any = useMemo(
        () => {
            return barChartFactory(
                "Antioxidant",
                props.ingredient.insights.antioxidant,
                theme.palette.error.main,
                theme.palette.text.secondary
            )
        },
        [
            props.ingredient,
            theme.palette.error.main,
            theme.palette.text.secondary,
        ]
    )

    const anticancerReactApexChart: any = useMemo(
        () => {
            return barChartFactory(
                "Anticancer",
                props.ingredient.insights.anticancer,
                theme.palette.error.main,
                theme.palette.text.secondary
            )
        },
        [
            props.ingredient,
            theme.palette.error.main,
            theme.palette.text.secondary,
        ]
    )

    const antithromboticReactApexChart: any = useMemo(
        () => {
            return barChartFactory(
                "Antithrombotic",
                props.ingredient.insights.antithrombotic,
                theme.palette.error.main,
                theme.palette.text.secondary
            )
        },
        [
            props.ingredient,
            theme.palette.error.main,
            theme.palette.text.secondary,
        ]
    )

    const preservativeReactApexChart: any = useMemo(
        () => {
            const preservativeValues = [
                props.ingredient.insights.antimicrobial,
                props.ingredient.insights.antiviral,
                props.ingredient.insights.antibacterial,
                props.ingredient.insights.antiparasite,
                props.ingredient.insights.antifungal,
            ]
            const preservativeLabels = [
                'antimicrobial',
                'antiviral',
                'antibacterial',
                'antiparasite',
                'antifungal',
            ]
            return {

                series: [{
                    name: 'Preservative',
                    data: preservativeValues,
                }],
                options: {
                    chart: {
                        width: '100%',
                        type: 'bar',
                        toolbar: {
                            show: false
                        }
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 10,
                            dataLabels: {
                                position: 'top',
                            },
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function (val: string) {
                            return val + "%";
                        },
                        offsetY: -20,
                        style: {
                            fontSize: '12px',
                            colors: [theme.palette.primary.main]
                        }
                    },
                    xaxis: {
                        categories: preservativeLabels,
                        position: 'top',
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        crosshairs: {
                            fill: {
                                type: 'gradient',
                                gradient: {
                                    colorFrom: '#D8E3F0',
                                    colorTo: '#BED1E6',
                                    stops: [0, 100],
                                    opacityFrom: 0.4,
                                    opacityTo: 0.5,
                                }
                            }
                        },
                        tooltip: {
                            enabled: true,
                        }
                    },
                    yaxis: {
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false,
                        },
                        labels: {
                            show: false,
                            formatter: function (val: string) {
                                return val + "%";
                            }
                        }
                    },
                    title: {
                        show: false,
                    }
                },
            };
        },
        [
            props.ingredient,
            theme.palette.primary.main,
            theme.palette.text.secondary,
        ]
    )

    const allergenicityReactApexChart: any = useMemo(
        () => {
            return {
                series: [props.ingredient.insights.allergenic],
                options: {
                    chart: {
                        width: '100%',
                        type: 'radialBar',
                        toolbar: {
                            show: false
                        }
                    },
                    plotOptions: {
                        radialBar: {
                            hollow: {
                                size: '70%',
                            },
                            dataLabels: {
                                show: true,
                                name: {
                                    show: true,
                                    color: theme.palette.text.secondary,
                                },
                                value: {
                                    show: true,
                                    color: theme.palette.text.secondary,
                                }
                            }
                        },
                    },
                    labels: ['Allergenicity'],
                    fill: {
                        type: 'solid',
                        colors: [theme.palette.warning.main],
                    }
                },
            };
        },
        [
            props.ingredient,
            theme.palette.primary.main,
            theme.palette.text.secondary,
        ]
    )

    const colorReactApexChart: any = useMemo(
        () => {
            const colorValues = [].map(() => faker.number.int({min: 0, max: 130}))
            const colorLabels = [].map((color: any) => color)
            return {
                series: [{
                    name: 'Likelihood',
                    data: colorValues,
                    color: theme.palette.success.main
                }],
                options: {
                    chart: {
                        width: '100%',
                        type: 'bar',
                        toolbar: {
                            show: false
                        }
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 4,
                            horizontal: true,
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    xaxis: {
                        categories: colorLabels,
                        labels: {
                            style: {
                                colors: theme.palette.text.secondary,
                                fontSize: 14
                            }
                        }
                    }
                }
            }
        },
        [props.ingredient, theme.palette.success.main, theme.palette.text.secondary]
    )

    return (
        <>
            <Grid2 size={5}>
                <Paper elevation={2} sx={{m: 1, p: 2, minHeight: 370, maxHeight: 370}}>
                    <Typography variant="h5"
                                color={theme.palette.primary.main}
                                textAlign={"center"}>
                        Taste
                    </Typography>
                    <Box id="taste-chart">
                        <ReactApexChart options={tasteReactApexChart.options}
                                        series={tasteReactApexChart.series}
                                        type="radar"
                                        height={360}/>
                    </Box>
                </Paper>
            </Grid2>
            <Grid2 size={7}>
                <Paper elevation={2} sx={{m: 1, p: 2, minHeight: 370, maxHeight: 370}}>
                    <Typography variant="h5"
                                color={theme.palette.warning.main}
                                textAlign={"center"}
                                >
                        Texture
                    </Typography>
                    <Box id="texture-chart">
                        <ReactApexChart options={textureReactApexChart.options}
                                        series={textureReactApexChart.series}
                                        type="line"
                                        height={340}/>
                    </Box>
                </Paper>
            </Grid2>
            <Grid2 size={12}>
                <Paper elevation={2} sx={{m: 1, p: 2, minHeight: 500, maxHeight: 500}}>
                    <Typography variant="h5"
                                color={theme.palette.success.main}
                                textAlign={"center"}
                                >
                        Aroma & Odor
                    </Typography>
                    <Box id="aroma-odor-chart">
                        <ReactApexChart options={aromaAndOdorReactApexChart.options}
                                        series={aromaAndOdorReactApexChart.series}
                                        type="bar"
                                        height={430}/>
                    </Box>
                </Paper>
            </Grid2>
            <Grid2 size={6}>
                <Paper elevation={2} sx={{m: 1, p: 2, minHeight: 500, maxHeight: 500}}>
                    <Typography variant="h5"
                                color={theme.palette.error.main}
                                textAlign={"center"}
                                >
                        Health
                    </Typography>
                    <Box id="antioxidant-chart">
                        <ReactApexChart options={antioxidantReactApexChart.options}
                                        series={antioxidantReactApexChart.series}
                                        type="bar"
                                        height={100}/>
                    </Box>
                    <Box id="antihypertensive-chart">
                        <ReactApexChart options={antihypertensiveReactApexChart.options}
                                        series={antihypertensiveReactApexChart.series}
                                        type="bar"
                                        height={100}/>
                    </Box>
                    <Box id="anticancer-chart">
                        <ReactApexChart options={anticancerReactApexChart.options}
                                        series={anticancerReactApexChart.series}
                                        type="bar"
                                        height={100}/>
                    </Box>
                    <Box id="antithrombotic-chart">
                        <ReactApexChart options={antithromboticReactApexChart.options}
                                        series={antithromboticReactApexChart.series}
                                        type="bar"
                                        height={100}/>
                    </Box>
                </Paper>
            </Grid2>
            <Grid2 size={6}>
                <Paper elevation={2} sx={{m: 1, p: 2, minHeight: 500, maxHeight: 500}}>
                    <Typography variant="h5"
                                color={theme.palette.primary.main}
                                textAlign={"center"}
                                >
                        Preservative
                    </Typography>
                    <Box id="antioxidant-chart">
                        <ReactApexChart options={preservativeReactApexChart.options}
                                        series={preservativeReactApexChart.series}
                                        type="bar"
                                        height={450}/>
                    </Box>
                </Paper>
            </Grid2>
            <Grid2 size={4}>
                <Paper elevation={2} sx={{m: 1, p: 2, minHeight: 500, maxHeight: 500}}>
                    <Typography variant="h5"
                                color={theme.palette.warning.main}
                                textAlign={"center"}
                                >
                        Safety
                    </Typography>
                    <Stack direction={"column"}
                           alignItems={"center"}
                           justifyContent={"center"}
                           spacing={2}>
                        <Box>
                            <Button variant={"outlined"}
                                    color={"warning"}
                                    size={"large"}
                                    startIcon={<HealthAndSafety color={"warning"}/>}>
                                LD50 - {props.ingredient.insights.toxicity}
                            </Button>
                        </Box>
                        <Box id="antioxidant-chart">
                            <ReactApexChart options={allergenicityReactApexChart.options}
                                            series={allergenicityReactApexChart.series}
                                            type="radialBar"
                                            height={350}/>
                        </Box>
                    </Stack>
                </Paper>
            </Grid2>
            <Grid2 size={8}>
                <Paper elevation={2} sx={{m: 1, p: 2, minHeight: 500, maxHeight: 500}}>
                    <Typography variant="h5"
                                color={theme.palette.success.main}
                                textAlign={"center"}
                                >
                        Color
                    </Typography>
                    <Box id="antioxidant-chart">
                        <ReactApexChart options={colorReactApexChart.options}
                                        series={colorReactApexChart.series}
                                        type="bar"
                                        height={450}/>
                    </Box>
                </Paper>
            </Grid2>
        </>
    )
}*/

function ListCardItem(props: { ingredient: any, title: string, filterInsights: any }) {
    // create an array of ingredient insights (key, value) pairs
    const cardInsightsRaw = Object.keys(props.ingredient.insights).map((key) => {
        if (props.filterInsights.includes(key)) {
            return {
                key: key,
                value: props.ingredient.insights[key]
            }
        }
    })

    // filter out undefined values
    const cardInsights: any = cardInsightsRaw.filter((insight) => insight !== undefined)

    return (
        <Grid2 size={6}>
            <Card>
                <CardContent>
                    <Typography variant={"h5"} color={"primary"}>
                        {props.title}
                    </Typography>
                    <List>
                        {cardInsights.map((insight: any) => {
                            if (insight.key === 'color' || insight.key === 'odorAroma') {
                                return (
                                    <ListItem>
                                        <ListItemText>
                                            <Stack direction={"row"}
                                                   alignItems={"center"}
                                                   justifyContent={"flex-start"}
                                                   spacing={1}>
                                                <Typography>
                                                    {insight.key}
                                                </Typography>
                                                <Chip label={insight.value} color={"success"} variant={"outlined"}/>
                                            </Stack>
                                            <Typography variant={"body2"} color={"textSecondary"}>
                                                Missing TEXT
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                )
                            } else {
                                return (
                                    <ListItem>
                                        <ListItemText>
                                            <Stack direction={"row"}
                                                   alignItems={"center"}
                                                   justifyContent={"flex-start"}
                                                   spacing={1}>
                                                <Typography>
                                                    {insight.key}
                                                </Typography>
                                                {
                                                    insight.value > 100 ?
                                                        <Chip label={'Experimental Evidence'} color={"success"}
                                                              variant={"outlined"}/>
                                                        :
                                                        <Chip label={insight.value} color={"success"}
                                                              variant={"outlined"}/>
                                                }
                                            </Stack>
                                            {
                                                insight.value > 100 ?
                                                    <Typography variant={"body2"} color={"textSecondary"}>
                                                        Missing TEXT
                                                    </Typography> :
                                                    <Typography variant={"body2"} color={"textSecondary"}>
                                                        Predicted
                                                    </Typography>
                                            }
                                        </ListItemText>
                                    </ListItem>
                                )
                            }
                        })}
                    </List>
                </CardContent>
            </Card>
        </Grid2>
    )
}

/*function ListView(props: {ingredient: MaterialEntity}) {

    return (
        <Grid2 container
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
              spacing={2}>
            {[].map((card: any) => (
                <ListCardItem ingredient={props.ingredient}
                              title={card.title}
                              filterInsights={card.insights}/>
            ))}
        </Grid2>
    )
}*/

// ----------------------------------------------------------------------
export default function IngredientData() {
    //const [view, setView] = useState('chart');

    // Load Ingredient data
    let {ingredientId} = useParams();
    const {isLoading, data: ingredientData} = useQuery(
        ingredientDetailQuery(Number(ingredientId))
    )

    return (
        <Grid2 container
               direction={"row"}
               justifyContent={'flex-start'}
               alignItems={'flex-start'}>
            <Grid2 size={12}>
                {
                    ingredientData ?
                        ingredientData.type !== "CPX" ?
                            <IngredientHighlights ingredient={ingredientData}/>
                            : <ComplexIngredientInsightsSection id={ingredientData.id}/>
                        :
                        <Typography>
                            No insights available
                        </Typography>
                }
            </Grid2>
        </Grid2>
    )
}

function ComplexIngredientInsightsSection(props: { id: number }) {

    const {
        status: ingredientStatus,
        data: ingredientsData,
    } = useQuery(
        ingredientInsightsQuery(props.id)
    );


    return (
        <Grid2 container spacing={3}>
            <Grid2 size={12}>
                <DataLoadingStatusHandler status={ingredientStatus}
                                          successRender={
                                              <Stack
                                                  direction="column"
                                              >
                                                  <AnnotationTypeBreakdown name={annotationTypes["TST"]}
                                                                           annotations={ingredientsData?.["TST"] ?? []}></AnnotationTypeBreakdown>
                                                  <AnnotationTypeBreakdown name={annotationTypes["ODO"]}
                                                                           annotations={ingredientsData?.["ODO"] ?? []}></AnnotationTypeBreakdown>
                                                  <AnnotationTypeBreakdown name={annotationTypes["TEC"]}
                                                                           annotations={ingredientsData?.["TEC"] ?? []}></AnnotationTypeBreakdown>
                                                  <AnnotationTypeBreakdown name={annotationTypes["REG"]}
                                                                           annotations={ingredientsData?.["REG"] ?? []}></AnnotationTypeBreakdown>
                                              </Stack>
                                          }
                                          loadingRender={
                                              <DataOperationStatus
                                                  icon={<CircularProgress/>}>
                                                  <Typography color={"textSecondary"}>
                                                      Analysing Product Composition.
                                                  </Typography>
                                              </DataOperationStatus>
                                          }
                />
            </Grid2>
        </Grid2>
    )
}
