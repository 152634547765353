export const ROLES = {
    qa: "qa",
    user: "user",
    admin: "admin",
    chat: "chat"
};

export const SCOPES = {
    overviewCanView: "overview.can-view",
    productsCanCreate: "products.can-create",
    productsCanEdit: "products.can-edit",
    productsCanDelete: "products.can-delete",
    productsCanView: "products.can-view",
    ingredientsCanCreate: "ingredients.can-create",
    ingredientsCanEdit: "ingredients.can-edit",
    ingredientsCanDelete: "ingredients.can-delete",
    ingredientsCanView: "ingredients.can-view",
    chatCanCreate: "chat.can-create",
    adminCanCreate: "admin.can-create",
    adminCanEdit: "admin.can-edit",
    adminCanDelete: "admin.can-delete",
    adminCanView: "admin.can-view"
};

export const PERMISSIONS = {
    [ROLES.qa]: [
        SCOPES.productsCanView,
        SCOPES.ingredientsCanView,
    ],
    [ROLES.chat]: [
        SCOPES.chatCanCreate,
    ],
    [ROLES.user]: [
        SCOPES.productsCanCreate,
        SCOPES.productsCanEdit,
        SCOPES.productsCanDelete,
        SCOPES.productsCanView,
        SCOPES.ingredientsCanCreate,
        SCOPES.ingredientsCanEdit,
        SCOPES.ingredientsCanDelete,
        SCOPES.ingredientsCanView,
        SCOPES.chatCanCreate,
    ],
    [ROLES.admin]: [
        SCOPES.productsCanCreate,
        SCOPES.productsCanEdit,
        SCOPES.productsCanDelete,
        SCOPES.productsCanView,
        SCOPES.ingredientsCanCreate,
        SCOPES.ingredientsCanEdit,
        SCOPES.ingredientsCanDelete,
        SCOPES.ingredientsCanView,
        SCOPES.chatCanCreate,
        SCOPES.adminCanCreate,
        SCOPES.adminCanEdit,
        SCOPES.adminCanDelete,
        SCOPES.adminCanView,
    ]
};
