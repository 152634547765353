import {
    createBrowserRouter,
    Link as RouterLink,
    matchPath,
    Navigate,
    Outlet,
    UIMatch,
    useLocation
} from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
// templates
import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";
// pages
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import OverviewPage, {overviewPageLoader} from './pages/OverviewPage';
import FoodsPage, {foodPageLoader} from "./pages/FoodsPage";
import FoodDetailPage, {foodDetailPageLoader} from "./pages/FoodDetailPage";
import IngredientsPage, {ingredientsPageLoader} from "./pages/IngredientsPage";
import IngredientDetailPage, {ingredientDetailLoader} from "./pages/IngredientDetailPage";
import NewFoodPage from "./pages/NewFoodPage";
import {fetchAccessToken} from './utils/auth';
import {FoodDetails, FoodSettingsSection} from "./sections/@dashboard/foods";
import FoodInsightsSection from "./sections/@dashboard/foods/FoodInsightsSection";
import FoodContentSection from "./sections/@dashboard/foods/FoodContentSection";
import {Link} from "@mui/material";
import {MaterialEntity, Product} from "./api";
import {IngredientData, IngredientDetails} from "./sections/@dashboard/ingredients";
import IngredientAlternatives from "./sections/@dashboard/ingredients/IngredientAlternatives";
import NewIngredientPage from "./pages/NewIngredientPage";
import IngredientComposition from "./sections/@dashboard/ingredients/IngredientComposition";
import {AdminPage} from "./pages/AdminPage";
import {AssistantPage} from "./pages/AssistantPage";
import FoodNutritionSection from "./sections/@dashboard/foods/FoodNutritionSection";
import IngredientNutritionSection from "./sections/@dashboard/ingredients/IngredientNutritionSection";
import {NutritionalParametersPage, StartPage} from "./sections/@dashboard/admin";
import {AdminTemplate} from "./access/IsAdmin";
import PermissionsGate from "./access/PermissionsGate";
import {SCOPES} from "./access/permission-maps";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
function LinkRouter(props: any) {
    return <Link {...props} component={RouterLink}/>;
}

export default function router(pca: any, queryClient: any) {
    return createBrowserRouter([
        {
            path: '',
            element: <>
                <AuthenticatedTemplate>
                    <DashboardLayout/>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Navigate to={"/login"}/>
                </UnauthenticatedTemplate>
            </>,
            children: [
                {
                    element: <Navigate to="overview"/>,
                    index: true,
                },
                {
                    path: 'overview',
                    element: <OverviewPage/>,
                    loader: async () => (await fetchAccessToken()) ? overviewPageLoader(queryClient) : null,
                    handle: {
                        // you can put whatever you want on a route handle
                        // here we use "crumb" and return some elements,
                        // this is what we'll render in the breadcrumbs
                        // for this route
                        crumb: ({pathname}: UIMatch) => <LinkRouter key="overview" underline="hover" color="inherit"
                                                                    to={pathname}>
                            Overview</LinkRouter>,
                    }
                },
                {
                    path: 'admin',
                    element: <>
                        <PermissionsGate children={<AdminPage/>} fallback={<Navigate to="/404" replace/>} scopes={
                            [SCOPES.adminCanCreate, SCOPES.adminCanView, SCOPES.adminCanDelete, SCOPES.adminCanEdit]
                        }
                        />
                    </>,
                    handle: {
                        crumb: ({pathname}: UIMatch) => <LinkRouter key="admin" underline="hover" color="inherit"
                                                                    to={pathname}>
                            Admin</LinkRouter>,
                    },
                    children: [
                        {
                            index: true,
                            element: <StartPage/>,
                        },
                        {
                            path: "nutritional-parameters",
                            element: <NutritionalParametersPage/>,
                            handle: {
                                crumb: ({pathname}: UIMatch) => <LinkRouter underline="hover" color="inherit"
                                                                            to={pathname}>
                                    Nutritional Parameters</LinkRouter>,
                            },
                        }
                    ]
                },
                {
                    path: 'assistant',
                    element: <PermissionsGate children={<AssistantPage/>} fallback={<Navigate to="/404" replace/>}
                                              scopes={
                                                  [SCOPES.chatCanCreate]
                                              }/>,
                    handle: {
                        crumb: ({pathname}: UIMatch) => <LinkRouter key="assistant" underline="hover" color="inherit"
                                                                    to={pathname}>
                            Assistant</LinkRouter>,
                    }
                },
                {
                    path: 'food-products',
                    id: 'food-products',
                    element: <PermissionsGate children={<Outlet/>} fallback={<Navigate to="/404" replace/>}
                                              scopes={
                                                  [SCOPES.productsCanCreate, SCOPES.productsCanDelete, SCOPES.productsCanView, SCOPES.productsCanDelete]
                                              }/>,
                    handle: {
                        crumb: ({pathname}: UIMatch) => <LinkRouter key="food-products" underline="hover"
                                                                    color="inherit"
                                                                    to={pathname}>Food Products</LinkRouter>,
                    },
                    children: [
                        {
                            index: true,
                            element: <FoodsPage/>,
                            loader: async () => (await fetchAccessToken()) ? foodPageLoader(queryClient) : null,
                        },
                        {
                            path: ':foodId',
                            element: <FoodDetailPage/>,
                            id: 'food-products-detail',
                            loader: async ({params}) => (await fetchAccessToken()) ? foodDetailPageLoader(queryClient, params) : null,
                            handle: {
                                crumb: ({pathname, data}: UIMatch<Product>) => <LinkRouter
                                    key="food-products-detail"
                                    underline="hover"
                                    color="inherit"
                                    to={pathname}>{data.name}</LinkRouter>,
                            },
                            children: [
                                {
                                    index: true,
                                    element: <FoodDetails/>,
                                    loader: async ({params}) => (await fetchAccessToken()) ? foodDetailPageLoader(queryClient, params) : null,
                                    handle: {
                                        crumb: ({pathname}: UIMatch) => {
                                            return <LinkRouter
                                                key="food-products-detail-root"
                                                underline="hover" color="inherit"
                                                to={pathname}>Details</LinkRouter>
                                        },
                                    },
                                },
                                {
                                    path: "insights",
                                    element: <FoodInsightsSection/>,
                                    handle: {
                                        crumb: ({pathname}: UIMatch) => <LinkRouter
                                            key="food-products-detail-insights"
                                            underline="hover" color="inherit"
                                            to={pathname}>
                                            Insights</LinkRouter>,
                                    },
                                },
                                {
                                    path: "composition",
                                    element: <FoodContentSection/>,
                                    handle: {
                                        crumb: ({pathname}: UIMatch) => <LinkRouter
                                            key="food-products-detail-composition"
                                            underline="hover" color="inherit"
                                            to={pathname}>
                                            Composition</LinkRouter>,
                                    },
                                },
                                {
                                    path: "nutrition",
                                    element: <FoodNutritionSection/>,
                                    handle: {
                                        crumb: ({pathname}: UIMatch) => <LinkRouter
                                            key="food-products-detail-nutrition"
                                            underline="hover" color="inherit"
                                            to={pathname}>
                                            Nutrition</LinkRouter>,
                                    },
                                },
                                {
                                    path: "settings",
                                    element: <FoodSettingsSection/>,
                                    handle: {
                                        crumb: ({pathname}: UIMatch) => <LinkRouter
                                            key="food-products-detail-settings"
                                            underline="hover" color="inherit"
                                            to={pathname}>
                                            Nutrition</LinkRouter>,
                                    },
                                },
                            ]
                        },
                        {
                            path: 'new-food-product',
                            element: <NewFoodPage/>,
                            handle: {
                                crumb: ({pathname}: UIMatch) => <LinkRouter
                                    key="food-products-new-food-product"
                                    underline="hover" color="inherit"
                                    to={pathname}>
                                    New Food Product</LinkRouter>,
                            }
                        }
                    ]
                },
                {
                    path: 'ingredients',
                    id: 'ingredients',
                    handle: {
                        crumb: ({pathname}: UIMatch) => <LinkRouter
                            key="ingredients"
                            underline="hover" color="inherit"
                            to={pathname}>Ingredients</LinkRouter>,
                    },
                    element: <PermissionsGate children={<Outlet/>} fallback={<Navigate to="/404" replace/>}
                                              scopes={
                                                  [SCOPES.ingredientsCanCreate, SCOPES.ingredientsCanDelete, SCOPES.ingredientsCanView, SCOPES.ingredientsCanDelete]
                                              }/>,
                    children: [
                        {
                            index: true,
                            element: <IngredientsPage/>,
                            loader: async () => (await fetchAccessToken()) ? ingredientsPageLoader(queryClient) : null
                        },

                        {
                            path: ':ingredientId',
                            element: <IngredientDetailPage/>,
                            id: 'ingredients-detail',
                            loader: async ({params}) => (await fetchAccessToken()) ? ingredientDetailLoader(queryClient, params) : null,
                            handle: {
                                crumb: ({pathname, data}: UIMatch<MaterialEntity>) => <LinkRouter
                                    key="ingredients-detail"
                                    underline="hover"
                                    color="inherit"
                                    to={pathname}>
                                    {data.name}</LinkRouter>,
                            },
                            children: [
                                {
                                    index: true,
                                    element: <IngredientDetails/>,
                                    loader: async ({params}) => (await fetchAccessToken()) ? ingredientDetailLoader(queryClient, params) : null,
                                    handle: {
                                        crumb: ({pathname}: UIMatch) => {
                                            return <LinkRouter
                                                key="ingredients-detail-root"
                                                underline="hover" color="inherit"
                                                to={pathname}>Details</LinkRouter>
                                        },
                                    },
                                },
                                {
                                    path: "composition",
                                    element: <IngredientComposition/>,
                                    loader: async ({params}) => (await fetchAccessToken()) ? ingredientDetailLoader(queryClient, params) : null,
                                    handle: {
                                        crumb: ({pathname}: UIMatch) => <LinkRouter
                                            key="ingredients-detail-composition"
                                            underline="hover" color="inherit"
                                            to={pathname}>
                                            Composition</LinkRouter>,
                                    },
                                },
                                {
                                    path: "nutrition",
                                    element: <IngredientNutritionSection/>,
                                    handle: {
                                        crumb: ({pathname}: UIMatch) => <LinkRouter underline="hover" color="inherit"
                                                                                    to={pathname}>
                                            Nutrition</LinkRouter>,
                                    },
                                },
                                {
                                    path: "insights",
                                    element: <IngredientData/>,
                                    loader: async ({params}) => (await fetchAccessToken()) ? ingredientDetailLoader(queryClient, params) : null,
                                    handle: {
                                        crumb: ({pathname}: UIMatch) => <LinkRouter
                                            key="ingredients-detail-insights"
                                            underline="hover" color="inherit"
                                            to={pathname}>
                                            Insights</LinkRouter>,
                                    },
                                },
                                {
                                    path: "alternatives",
                                    element: <IngredientAlternatives/>,
                                    handle: {
                                        crumb: ({pathname}: UIMatch) => <LinkRouter
                                            key="ingredients-detail-alternatives"
                                            underline="hover" color="inherit"
                                            to={pathname}>
                                            Alternatives</LinkRouter>,
                                    },
                                },
                            ]
                        },
                        {
                            path: 'new-ingredient',
                            element: <NewIngredientPage/>,
                            handle: {
                                crumb: ({pathname}: UIMatch) => <LinkRouter
                                    key="ingredients-new-ingredient"
                                    underline="hover" color="inherit"
                                    to={pathname}>
                                    New Ingredient</LinkRouter>,
                            }
                        }
                    ]
                },
                // {
                //     path: 'reformulations',
                //     element: <ReformulationsPage/>,
                //     id: 'reformulations',
                //     // loader: async () => reformulations,
                // },
                // {
                //     path: 'reformulations/:reformulationId',
                //     element: <ReformulationDetailPage/>,
                //     id: 'reformulations-detail',
                //     // loader: async ({params}) => reformulations.find((reformulation) => reformulation.uid === Number(params.reformulationId)),
                // },
                // {
                //     path: 'reformulations/new-reformulation',
                //     element: <NewReformulationPage/>
                // },
            ],
        },
        {
            path: 'login',
            element: <LoginPage/>,
        },
        {
            element: <SimpleLayout/>,
            children: [
                {element: <Navigate to="overview"/>, index: true},
                {path: '404', element: <Page404/>},
                {path: '*', element: <Navigate to="/404"/>},
            ],
        },
        {
            path: '*',
            element: <Navigate to="/404" replace/>,
        },
    ]);
}

export function useRouteMatch(patterns: readonly string[]) {
    const {pathname} = useLocation();

    for (let i = 0; i < patterns.length; i += 1) {
        const pattern = patterns[i];
        const possibleMatch = matchPath(pattern, pathname);
        if (possibleMatch !== null) {
            return possibleMatch;
        }
    }

    return null;
}