// @mui
import {Assistant} from "@mui/icons-material";
import {AssistantTool} from "../../../tools/AssistantTool";
import React from "react";

// ----------------------------------------------------------------------
export type TToolConfig = {
    title: string,
    icon: React.ReactNode,
    tool: React.ElementType<{ handleDrawerOpen: () => void; handleDrawerClose: () => void; }>
}

export const toolConfig: TToolConfig[] = [
    {
        title: 'Assistant',
        icon: <Assistant/>,
        tool: AssistantTool
    },
];

