// ----------------------------------------------------------------------

import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

function NutritionClaims(props: { rows: object }) {

    return (
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 150}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Claim</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(props.rows).filter((claim) => claim[1] === true).map(([label, possible]) => (
                        <TableRow
                            key={label}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell component="th" scope="row">
                                {label}
                            </TableCell>
                            <TableCell align="right">{possible ? "Meets" : "Does not meet"}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}


export default NutritionClaims;
