import {Helmet} from 'react-helmet-async';
// react-router
import {Navigate} from "react-router-dom";
// @mui
import {styled} from '@mui/material/styles';
import {Box, Grid2, IconButton, Link, Stack, Typography} from '@mui/material';
import {Copyright, LinkedIn} from "@mui/icons-material";
// hooks
import useResponsive from '../hooks/useResponsive';
// sections and components
import {LoginForm} from '../sections/auth/login';
// @msal and authentication
import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";

// ----------------------------------------------------------------------
const StyledRoot = styled('div')(({theme}) => ({
	[theme.breakpoints.up('md')]: {
		display: 'flex',
	},
}));

const StyledContent = styled('div')(({ theme }) => ({
	maxWidth: 480,
	margin: 'auto',
	minHeight: '60vh',
	display: 'flex',
	justifyContent: 'center',
	flexDirection: 'column',
	padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

function LoginContent() {
	return (
		<StyledContent>
			<Box sx={{mb: 5, mx: 'auto'}}>
				<img src="/assets/cibus_B.png" alt="CibusAI" width={200}/>
			</Box>
			<LoginForm/>
			<Link href="mailto:info@omniumai.com" variant="subtitle2" sx={{mt: 3}}>
				Don't have an account? Contact us
			</Link>
		</StyledContent>
	)
}

export default function LoginPage() {
	const mdUp = useResponsive('up', 'md');

	const backgroundImgStyle = {
		minHeight: "100vh",
		backgroundImage: `url(/assets/berries.jpg)`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	}

	return (
		<>
			<Helmet>
				<title> Login | CibusAI</title>
			</Helmet>

			<AuthenticatedTemplate>
				<Navigate to={"/"}/>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<StyledRoot>
					<Grid2 container
						  sx={{minWidth: "100vw"}}
						  direction="row"
						  justifyContent="center"
						  alignItems="center">
						{mdUp ?
							<>
								<Grid2 size={6} sx={{...backgroundImgStyle}}  />
								<Grid2 size={6}>
									<Stack justifyContent="center" alignItems="center">
										<LoginContent/>
										<Stack direction={"row"}
											   justifyContent={'center'}
											   alignItems={'center'}
											   spacing={1}>
											<Typography variant={'body2'} color={'textSecondary'}>
												All Rights Reserved
											</Typography>
											<Typography variant={'body2'} color={'textSecondary'}>
												|
											</Typography>
											<Link href="https://omniumai.com"
												  variant={'body2'}
												  underline={'hover'}
												  color={'textSecondary'}>
												<Copyright sx={{fontSize: 10}} /> OmniumAI 2023
											</Link>
											<Typography variant={'body2'} color={'textSecondary'}>
												|
											</Typography>
											<IconButton href={'https://www.linkedin.com/company/omniumai'}>
												<LinkedIn/>
											</IconButton>
										</Stack>
									</Stack>
								</Grid2>
							</>
							:
							<>
								<Grid2 size={12}>
									<Stack justifyContent="center" alignItems="center" sx={{mb: 1}}>
										<LoginContent/>
										<Typography variant="body2" color="textSecondary">
											&copy; 2023 CibusAI. All rights reserved.
										</Typography>
										<Link href="https://omniumai.com" variant="subtitle2">
											Powered by Omnium AI
										</Link>
									</Stack>
								</Grid2>
							</>
						}
					</Grid2>
				</StyledRoot>
			</UnauthenticatedTemplate>
		</>
	);
}
