import {Helmet} from "react-helmet-async";
import {useState} from "react";
// @mui
import {Box, Button, Grid2} from "@mui/material";
// sections and components
import {NewFoodDescription, NewFoodIngredients, NewFoodStepper} from "../sections/@dashboard/foods/new-foods";
import {useNavigate} from "react-router-dom";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import openAPIGeneratorFoodDesignerInstance from "../openAPIGeneratorFoodDesignerInstance";
import {apiRoutes} from "../config";

export function NewFoodAdvancedPage() {
    const initialState = {
        'name': null,
        'description': null,
        'serving': null,
        'serving_unit': 'g',
        'price': null,
        'price_unit': '€',
        'contents': [],
    }
    const [state, setState] = useState(initialState)
    const [activeStep, setActiveStep] = useState(0);

    const handleChanges = (key: any) => {
        return (event: any) => {
            setState({...state, [key]: event.target.value})
        }
    }
    return (
        <>
            <Helmet>
                <title> New Food Product | CibusAI </title>
            </Helmet>
            <Box>
                <NewFoodStepper activeStep={activeStep} setActiveStep={setActiveStep} state={state}>
                    {activeStep === 0 ?
                        <NewFoodDescription state={state} handleChanges={handleChanges}/>
                        :
                        <NewFoodIngredients state={state} setState={setState} handleChanges={handleChanges}/>
                    }
                </NewFoodStepper>
            </Box>
        </>
    )
}

export default function NewFoodPage() {
    const initialState = {
        'name': undefined,
        'description': undefined,
        'is_public': false
    }
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const [state, setState] = useState(initialState)

    const handleChanges = (key: any) => {
        return (event: any) => {
            setState({...state, [key]: event.target.value})
        }
    }

    const createFoodProductMutation = useMutation({
        mutationFn: () => {
            // @ts-ignore
            return openAPIGeneratorFoodDesignerInstance.foodDesignerProductsCreate(state)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [apiRoutes.foodDesigner.products.baseEndpoint]})
        },
    })

    const handleCreateProduct = async () => {
        await createFoodProductMutation.mutateAsync().then((value) =>
            navigate(`/food-products/${value.data.id}`)
        ).catch(
            (reason) => navigate(`/food-products/`)
        )
    }

    return (
        <>
            <Helmet>
                <title> New Food Product | CibusAI </title>
            </Helmet>
            <Grid2 container spacing={2}>
                <Grid2 size={12}>
                    <NewFoodDescription state={state} handleChanges={handleChanges}/>
                </Grid2>
                <Grid2 size={12}>
                    <Button variant={'outlined'}
                            size={'large'}
                            color={'success'}
                            type={'submit'}
                            onClick={handleCreateProduct}>
                        Submit
                    </Button>
                </Grid2>
            </Grid2>
        </>
    )
}