import {CircularProgress, Fade, Stack, Typography} from "@mui/material";
import {searchResponseTime} from "../../config";
import React from "react";
import {Error, Search} from "@mui/icons-material";


export default function DataOperationStatus(props: { icon: React.ReactNode, children: React.ReactNode }) {
    return <Stack
        justifyContent={"center"}
        alignItems={"center"}
        padding={"2em"}
    >
        <Fade
            in={true}
            style={{
                transitionDelay: searchResponseTime
            }}
            unmountOnExit
        >
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                {props.icon}
                <>
                    {props.children}
                </>
            </Stack>
        </Fade>
    </Stack>
}

export function EmptyStatus() {
    return (
        <DataOperationStatus
            icon={<Search fontSize={"large"} color={"disabled"}/>}>
            <Typography color={"textSecondary"}>
                Nothing found.
            </Typography>
        </DataOperationStatus>
    )
}

export function LoadingStatus() {
    return (
        <DataOperationStatus
            icon={<CircularProgress/>}>
            <Typography color={"textSecondary"}>
                Loading.
            </Typography>
        </DataOperationStatus>
    )
}

export function ErrorLoadingStatus() {
    return (
        <DataOperationStatus
            icon={<Error fontSize={"large"} color={"disabled"}/>}>
            <Typography color={"textSecondary"}>
                Error loading ingredients.
            </Typography>
        </DataOperationStatus>
    )
}