// ----------------------------------------------------------------------

export default function Paper(theme?: any) {
    return {
        MuiPaper: {
            defaultProps: {
                elevation: 1,
            },
            styleOverrides: {
                root: {
                    backgroundImage: 'none',
                },
            },
        },
    };
}
