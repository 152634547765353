import {QueryStatus} from "@tanstack/react-query";
import React from "react";
import {EmptyStatus, ErrorLoadingStatus, LoadingStatus} from "../data-operation-status";


export default function DataLoadingStatusHandler(props: {
    status: QueryStatus
    count?: number
    successRender: React.ReactNode
    loadingRender?: React.ReactNode
    emptyRender?: React.ReactNode
    errorRender?: React.ReactNode
}) {
    const complete_status = (props.status === "success" && props.count === 0) ? "empty" : props.status

    const selectRender = (status: QueryStatus | "empty") => {
        switch (status) {
            case "success":
                return props.successRender;
            case "empty":
                return props.emptyRender ?? <EmptyStatus/>;
            case "error":
                return props.errorRender ?? <ErrorLoadingStatus/>;
            case "pending":
                return props.loadingRender ?? <LoadingStatus/>;
            default:
                return <>ERROR: {props.status} IS INVALID</>
        }
    }

    return (
        <>
            {selectRender(complete_status)}
        </>
    )
}