import {Add} from "@mui/icons-material";
import {Box, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {Conversation, Message} from "../api";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {Grid} from "@mui/system";
import openAPIGeneratorChatInstance from "../openAPIGeneratorChatInstance";
import {ChatBot, ChatField, ChatHistory, SimplifiedMessage} from "../components/chatbot/ChatBot";
import {defaultChatTools} from "../config";

export function AssistantPage() {
    const queryClient = useQueryClient();
    const [selectedConversation, setSelectedConversation] = useState<Conversation | undefined>(undefined);

    // Chat State
    const [chatConversation, setChatConversation] = useState<any[]>([]);
    const [chatMessage, setChatMessage] = useState<string>('');

    // Global Operations
    const handleStateReset = (conversation?: Conversation) => {
        setSelectedConversation(conversation ?? undefined); // Update the current tab
        setChatConversation([]); // Clear chat in case of an error
        setChatMessage('');
    }

    // Conversation Operations
    // Mutation to create a new conversation
    const newConversationMutation = useMutation({
        mutationFn: () => openAPIGeneratorChatInstance.assistantConversationsCreate(),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['conversations']})
        }
    })

    // Mutation to delete a conversation
    const deleteConversationMutation = useMutation({
        mutationFn: (conversationId: number) => openAPIGeneratorChatInstance.assistantConversationsDestroy(conversationId),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['conversations']})
        }
    })

    const handleConversationChange = async (conversation: Conversation) => {
        try {
            // Fetch the messages for the selected conversation
            const response = await openAPIGeneratorChatInstance.assistantConversationsMessagesList(conversation.id);
            console.log('Updated chatConversation:', response.data);

            // Ensure data is valid before updating the state
            const messages: SimplifiedMessage[] = response.data.results.map((message: Message) => ({
                id: message.id,
                role: message.role,
                content: message.content,
                timestamp: message.timestamp,
                feedback: message.feedback,
            }));
            setSelectedConversation(conversation); // Update the current tab
            setChatConversation(messages); // Update the chat conversation state
            setChatMessage('');
        } catch (error) {
            console.error('Failed to fetch messages for the selected conversation:', error);
            handleStateReset()
        }
    };


    const handleCreateConversation = async () => {
        setSelectedConversation(undefined); // Update the current tab
        setChatConversation([]);
        setChatMessage('');

        try {
            const response = await newConversationMutation.mutateAsync();
            if (response.data) {
                const newConversation = response.data;
                handleStateReset(newConversation);
                console.log('New conversation:', newConversation.id);
                return newConversation
            } else return;
        } catch (error) {
            console.error('Failed to create a new conversation:', error);
            return;
        }
    }

    const handleDeleteConversation = (conversation: Conversation) => {
        try {
            deleteConversationMutation.mutate(conversation.id);
            if (conversation.id === selectedConversation?.id) {
                handleStateReset();
            }
            console.log('Deleted conversation:', conversation.id);
        } catch (error) {
            console.error('Failed to delete the selected conversation:', error);
        }
    }

    // Chat operations
    // Reference to the bottom of the chat
    const chatEndRef = useRef<HTMLDivElement | null>(null);

    // Mutation to delete a conversation
    const newMessageMutation = useMutation({
        mutationFn: (data: {
            activeConversation: Conversation,
            formData: Message
        }) => openAPIGeneratorChatInstance.assistantConversationsChatCreate(data.activeConversation.id, data.formData),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['conversations']})
        }
    })


    const handleChatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChatMessage(event.target.value);
    };

    // Send a message to the chat
    const handleChatSendMessage = async () => {
        if (chatMessage === '') return;
        let activeConversation

        if (selectedConversation === undefined) {
            console.error('No conversation selected.');
            activeConversation = await handleCreateConversation()
            if (!activeConversation) {
                return;
            }
        } else {
            activeConversation = selectedConversation;
        }

        console.log('Selected conversation:', activeConversation.id);

        const newMessage: { role: any, content: any, conversation: any } = {
            role: 'user',
            content: chatMessage,
            conversation: activeConversation.id
        };

        setChatConversation([...chatConversation, newMessage, {role: 'assistant', content: '...'}]);
        setChatMessage('');

        const formData = {
            role: newMessage.role,
            content: newMessage.content,
            tools_selected: defaultChatTools,
            conversation: activeConversation.id
        }
        try {
            // @ts-ignore
            const {data} = await newMessageMutation.mutateAsync({activeConversation, formData});
            if (data && data.id && data.role && data.content) {
                setChatConversation([...chatConversation, {
                    role: newMessage.role,
                    content: newMessage.content
                }, {id: data.id, role: data.role, content: data.content}]);
            }
        } catch (error) {
            setChatConversation([...chatConversation, newMessage, {
                role: 'assistant',
                content: 'An error has occurred. Please contact the administrator',
            }]);
        }
    };

    // Scroll to the bottom whenever chatConversation changes
    useEffect(() => {
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [chatConversation]);

    return (
        <Grid container>
            <Grid size={3}>
                <ListItem
                    key={"new-chat"}
                    disablePadding>
                    <ListItemButton
                        onClick={() => handleStateReset()}
                    >
                        <ListItemIcon>
                            <Add/>
                        </ListItemIcon>
                        <ListItemText
                            primary={`New Chat`}
                        />
                    </ListItemButton>
                </ListItem>
                <ChatHistory
                    handleConversationChange={handleConversationChange}
                    deleteConversation={handleDeleteConversation}
                    maxEntries={6}
                />
            </Grid>
            <Grid size={9}>
                {/* Middle chat area that should expand to fill available space */}
                <Box
                    sx={{
                        p: 1,
                        height: 'calc(100vh - 230px)',
                        overflowY: 'auto',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                    <Box sx={{flex: 1, overflowY: 'auto'}}>
                        <ChatBot
                            conversation={chatConversation}
                            message={chatMessage}
                            handleChange={handleChatChange}
                            handleSend={handleChatSendMessage}
                        />
                        {/* Dummy element to mark the end of the chat */}
                        <div ref={chatEndRef}></div>
                    </Box>
                </Box>

                {/* Bottom input field */}
                <Box sx={{p: 1}}>
                    <ChatField
                        label={`${chatMessage.length ?? 0}/500`}
                        message={chatMessage}
                        handleChange={handleChatChange}
                        handleSend={handleChatSendMessage}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}