import {Helmet} from 'react-helmet-async';
// hooks
import React, {useState} from "react";
// @mui
import {
    Box,
    Button,
    Grid2,
    InputAdornment,
    List,
    ListItemButton, ListItemIcon, ListItemText,
    Menu,
    MenuItem,
    Stack,
    Typography
} from '@mui/material';
import {Add, FileUploadOutlined, Folder, FolderShared, Search} from "@mui/icons-material";
import Pagination from '@mui/material/Pagination';
// sections and components
import {SortFoodCardList} from "../sections/@dashboard/foods";
import {StyledSearch} from "../components/search";
import {FoodCard} from "../components/food-card";
// router
import {NavLink as RouterLink} from "react-router-dom";
// utils
import {useQuery} from '@tanstack/react-query';
import {apiRoutes} from "../config";
import {SortOption, SortOptionCreateParams} from "../utils/filteringAndSorting";
import openAPIGeneratorFoodDesignerInstance from "../openAPIGeneratorFoodDesignerInstance";
import {DataLoadingStatusHandler} from "../components/data-loading-status-handler";
import {FilterTopicTypographyStyle, IngredientFilters} from "./IngredientsPage";
import FilterBar from "../components/filter-bar";
import PermissionsGate from "../access/PermissionsGate";
import {SCOPES} from "../access/permission-maps";

// ----------------------------------------------------------------------

const sortInitialState: SortOption = {
    property: 'none',
    order: 'asc',
    label: 'None'
}

export const foodPageQuery = (isOwner?: boolean, isPublic?: boolean, searchQuery?: string, sort?: SortOption, page?: number, pageSize?: number) => ({
    queryKey: [apiRoutes.foodDesigner.products.baseEndpoint, isOwner, isPublic, searchQuery, sort, page, pageSize],
    queryFn: () => {
        return openAPIGeneratorFoodDesignerInstance.foodDesignerProductsList(isOwner, isPublic, sort ? SortOptionCreateParams(sort) : undefined, page, pageSize, searchQuery)
            .then(response => response.data)
    }
})


export const foodPageLoader = async (queryClient: any, params?: any) => {
    const query = foodPageQuery(true, undefined, '', sortInitialState, 1, 9)
    return queryClient.ensureQueryData(query)
}

export default function FoodsPage() {

    const [page, setPage] = useState(1)
    const [searchQuery, setSearchQuery] = useState('');
    const [creator, setCreator] = useState(true);

    const sortOptions: SortOption[] = [
        {property: 'none', order: "asc", label: 'None'},
        {property: 'date_created', order: "desc", label: 'Newest'},
        {property: 'date_created', order: "asc", label: 'Oldest'},
        {property: 'name', order: "asc", label: 'Asc: Name'},
        {property: 'name', order: "desc", label: 'Desc: Name'},
    ];

    const creatorOptions = [
        {value: true, label: 'My Products', icon: <Folder/>},
        {value: false, label: 'Shared', icon: <FolderShared/>},
    ];

    const [sort, setSort] = useState(sortInitialState);

    const pageSize = 9

    const {data: foodProductsData, status} = useQuery(
        foodPageQuery(creator, undefined, searchQuery, sort, page, pageSize)
    )

    const handleFilterByName = (event: any) => {
        setPage(1);
        setSearchQuery(event.target.value.toString);
    };

    const handlePageChange = (event: any, page: number) => {
        setPage(page);
    }

    return (
        <>
            <Helmet>
                <title> Food Products | CibusAI </title>
            </Helmet>

            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
            }}>
                {/* Sidebar with Tabs */}
                <FilterBar>
                    <Typography
                        gutterBottom
                        sx={FilterTopicTypographyStyle}
                    >
                        Collection
                    </Typography>
                    <List>
                        {creatorOptions.map((option) => (
                            <ListItemButton
                                key={option.label}
                                // @ts-ignore
                                onClick={() => setCreator(option.value)}
                                dense
                                selected={creator === option.value}
                            >
                                <ListItemIcon>
                                    {option.icon}
                                </ListItemIcon>
                                <ListItemText primary={option.label}/>
                            </ListItemButton>
                        ))}
                    </List>
                    <SortFoodCardList value={sort} setValue={setSort} sortOptions={sortOptions}/>
                </FilterBar>
                {/* Main Content Section */}
                <Box>
                    <Grid2 container
                           alignItems="center"
                           justifyContent="space-between"
                           spacing={2}>
                        <Grid2 size={{xs: 12, md: 6}}>
                            <StyledSearch
                                fontSize={16}
                                width={400}
                                value={searchQuery}
                                onChange={handleFilterByName}
                                placeholder="Search food product..."
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Search/>
                                    </InputAdornment>
                                }
                            />
                        </Grid2>
                        <PermissionsGate fallback={<></>} scopes={[SCOPES.productsCanCreate]}>
                            <Grid2 size={{xs: 12, md: 6}}>
                                <Stack direction="row"
                                       justifyContent={"flex-end"}
                                       spacing={2}>
                                    <Button variant="outlined"
                                            size={"large"}
                                            color={"success"}
                                            disabled
                                            startIcon={<FileUploadOutlined/>}>
                                        Bulk Import
                                    </Button>
                                    <Button variant="outlined"
                                            size={"large"}
                                            startIcon={<Add/>}
                                            component={RouterLink}
                                            to={"new-food-product"}>
                                        New Food Product
                                    </Button>
                                </Stack>
                            </Grid2>
                        </PermissionsGate>
                        <Grid2 size={12}>
                            <DataLoadingStatusHandler
                                status={status}
                                count={foodProductsData?.count}
                                successRender={
                                    <Grid2 container spacing={3} size={12}>
                                        {foodProductsData?.results.map((food) => (
                                            <Grid2 size={{xs: 12, md: 6, lg: 4}} key={food.id}>
                                                <FoodCard food={food}/>
                                            </Grid2>))}
                                        <Grid2 size={12} alignItems={"center"}>
                                            <Stack alignItems={"center"}>
                                                <Pagination
                                                    variant="outlined"
                                                    shape="rounded"
                                                    count={Math.ceil(foodProductsData ? foodProductsData.count / pageSize : 0)}
                                                    page={page}
                                                    onChange={handlePageChange}
                                                />
                                            </Stack>
                                        </Grid2>
                                    </Grid2>
                                }
                            />
                        </Grid2>
                    </Grid2>
                </Box>
            </Box>
        </>
    );
}
