import ReactDOM from 'react-dom/client';

//
import App from './App';
// auth
import msalInstance from "./msalInstance";
// Query State
import {QueryClient} from '@tanstack/react-query';
// routes
import router from './routes';

// ----------------------------------------------------------------------
const docElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(docElement);
export const queryClient = new QueryClient()
export const loadedRouter = router(msalInstance, queryClient)

root.render(<App pca={msalInstance} queryClient={queryClient} router={loadedRouter}/>);
export {AssistantTool} from "./tools/AssistantTool";