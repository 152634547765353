import {Chip, Grid2} from "@mui/material";
import {annotationTypeSets} from "../../config";
import {filter_annotations_by_type_set, uniqueAnnotationsLabels} from "../../utils/material_entities";
import {MaterialEntity, MaterialEntityAnnotation} from "../../api";

export default function IngredientInsightsGrid(props: { ingredient: MaterialEntity }) {

    const insights = uniqueAnnotationsLabels(filter_annotations_by_type_set(props.ingredient, annotationTypeSets.insights))
        .map((insights: MaterialEntityAnnotation) => insights.label)

    return (
        <Grid2 container
              spacing={1}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
              sx={{pl: 0}}>
            {insights.length > 4 ?
                <>
                    <Grid2 size={"auto"}>
                        <Chip label={insights[0]} size={"small"} color={"primary"}/>
                    </Grid2>
                    <Grid2 size={"auto"}>
                        <Chip label={insights[1]} size={"small"} color={"primary"}/>
                    </Grid2>
                    <Grid2 size={"auto"}>
                        <Chip label={insights[2]} size={"small"} color={"primary"}/>
                    </Grid2>
                    <Grid2 size={"auto"}>
                        <Chip label={insights[3]} size={"small"} color={"primary"}/>
                    </Grid2>
                    <Grid2 size={"auto"}>
                        <Chip label={"+" + (insights.length - 4)} size={"small"} color={"primary"}/>
                    </Grid2>
                </>
                : insights.map((element: any) => {
                    return (
                        <Grid2 size={"auto"} key={element}>
                            <Chip label={element} size={"small"} color={"primary"}/>
                        </Grid2>
                    )
                })
            }
        </Grid2>
    )
}
