import {Helmet} from 'react-helmet-async';
// @mui
import {Box, Divider, Tab, Tabs, Typography,} from '@mui/material';
import {TabContext} from "@mui/lab";
import {BarChart, DonutLarge, InfoOutlined, Insights, TipsAndUpdatesOutlined} from "@mui/icons-material";
// sections
import {QueryClient, useQuery} from "@tanstack/react-query";
import {apiRoutes} from "../config";
// react router
import {Link as RouterLink, Outlet, useParams} from "react-router-dom";
import openAPIGeneratorMaterialLibraryInstance from "../openAPIGeneratorMaterialLibraryInstance";
import {useRouteMatch} from "../routes";
import {styled} from "@mui/material/styles";
import {EditIngredientMetadata} from "../sections/@dashboard/ingredients/EditIngredientMetadata";
import {MaterialEntity} from "../api";
// ----------------------------------------------------------------------
export const ingredientDetailQuery = (ingredientId: number) => ({
        queryKey: [apiRoutes.materialLibrary.materialEntity.baseEndpoint, ingredientId.toString()],
        queryFn: () => openAPIGeneratorMaterialLibraryInstance
            .materialLibraryMaterialEntitiesRetrieve(ingredientId)
            .then(
                response => response.data
            ),
    }
)
export const ingredientDetailLoader = async (queryClient: QueryClient, params: any) => {
    const query = ingredientDetailQuery(params.ingredientId)
    return queryClient.ensureQueryData(query)
}

export const ingredientCompoundDetailQuery = (ingredientId: number) => ({
        queryKey: [apiRoutes.materialLibrary.compounds.baseEndpoint, ingredientId.toString()],
        queryFn: () => openAPIGeneratorMaterialLibraryInstance
            .materialLibraryCompoundsRetrieve(ingredientId)
            .then(
                response => response.data
            ),
    }
)

export function editableMaterialEntity(materialEntity: MaterialEntity) {
    return materialEntity.type === "CPX" && !(materialEntity.cibus_id)
}


export default function IngredientDetailPage() {
    // handle tabs
    const routeMatch = useRouteMatch([
        '/ingredients/:ingredientId/composition',
        '/ingredients/:ingredientId/insights',
        '/ingredients/:ingredientId/alternatives',
        '/ingredients/:ingredientId/nutrition',
        '/ingredients/:ingredientId'
    ]);
    const currentTab = routeMatch?.pattern?.path;

    let {ingredientId} = useParams();

    const {data: ingredientData} = useQuery(
        ingredientDetailQuery(Number(ingredientId))
    )

    return (
        <>
            <Helmet>
                <title> Ingredients | CibusAI </title>
            </Helmet>
            {ingredientData ?
                <Box>
                    <Typography variant={"h4"}>
                        {ingredientData.name} {editableMaterialEntity(ingredientData) ?
                        <EditIngredientMetadata ingredient={ingredientData}/> : ""}
                    </Typography>
                    <TabContext value={currentTab ?? "/ingredients/:ingredientId"}>
                        <Tabs value={currentTab} aria-label="ingredient-tabs">
                            <Tab icon={<InfoOutlined/>}
                                 label="Details"
                                 value="/ingredients/:ingredientId"
                                 component={RouterLink}
                                 to={""}
                            />
                            <Tab icon={<BarChart/>}
                                 label="Composition"
                                 value="/ingredients/:ingredientId/composition"
                                 component={RouterLink}
                                 to={"composition"}
                            />
                            <Tab icon={<DonutLarge/>}
                                 label="Nutrition"
                                 value="/ingredients/:ingredientId/nutrition"
                                 component={RouterLink}
                                 to={"nutrition"}
                            />
                            <Tab icon={<Insights/>}
                                 label="Insights"
                                 value="/ingredients/:ingredientId/insights"
                                 component={RouterLink}
                                 to={"insights"}
                            />
                            <Tab icon={<TipsAndUpdatesOutlined/>}
                                 label="Alternatives"
                                 value="/ingredients/:ingredientId/alternatives"
                                 component={RouterLink}
                                 to={"alternatives"}
                            />
                        </Tabs>
                        <Divider/>
                        <TabOutletPanel>
                            <Outlet/>
                        </TabOutletPanel>
                    </TabContext>
                </Box> : <></>
            }
        </>
    );
}

export const TabOutletPanel = styled("div")(() => ({
    padding: 24,
}));