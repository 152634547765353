import {Outlet, useLocation, useParams, useRouteLoaderData} from 'react-router-dom';
// @mui
import {Box, Container, Toolbar} from "@mui/material";
// components
import Header from './header';
import Nav from './nav';
import ToolWindow from "./tool_window";

// ----------------------------------------------------------------------

export default function DashboardLayout() {

    const params = useParams();
    const location = useLocation();
    const base = location.pathname.split('/')[1];
    const loaderData = useRouteLoaderData(base + (Object.keys(params).length > 0 ? "-detail" : ""));

    return (
        <Box sx={{display: 'flex'}}>
            <Header/>
            <Nav/>
            <Container maxWidth="xl">
                <Toolbar/>
                <Outlet/>
            </Container>
            <ToolWindow/>
        </Box>
    );
}
