import {Box, CircularProgress, Typography} from "@mui/material";

export default function CircularProgressWithLabel(props: any) {

    return (
        <Box sx={{position: 'relative', display: 'inline-flex'}}>
            <CircularProgress variant="determinate" value={props.value} size={props.size}/>
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {props.multiplicationFactor ?
                    <Typography variant="body2" component="div" color="textSecondary">
                        {`${props.value * props.multiplicationFactor} ${props.unit}`}
                    </Typography> :
                    <Typography variant="body2" component="div" color="textSecondary">
                        {`${props.value} ${props.unit}`}
                    </Typography>
                }
            </Box>
        </Box>
    );
}

export function DailyPercentageIntakeWheelWithLabel(props: {
    value: number | string,
    reference: number,
    unit: string,
    size?: string | number
}) {

    return (
        <Box sx={{position: 'relative', display: 'inline-flex'}}>
            <CircularProgress variant="determinate" value={Number(props.value) / props.reference * 100}
                              size={props.size}/>
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {props.value} {props.unit}
            </Box>
        </Box>
    );
}