// @mui
import {Box, Grid2, Paper, Stack, Tooltip, Typography} from "@mui/material";
import {CalendarToday, Extension, InfoRounded, MonetizationOnOutlined,} from "@mui/icons-material";
// components
import {ScoreAvatar} from "../nutritional-score-avatar";
import {ChipStack} from "../chip-stacks";
// utils
import {fDate} from "../../utils/formatTime";
import {imageFallBacks, nutritionConfig} from "../../config";
import {DailyPercentageIntakeWheelWithLabel} from "../progress-label/CircularProgressLabel";
import {Formulation, MaterialEntity, Product} from "../../api";
import React from "react";
import {useQuery} from "@tanstack/react-query";
import {formulationCompositionSummaryPageQuery} from "../../pages/FoodDetailPage";

// ----------------------------------------------------------------------
export default function FoodInfoCard(props: { food: Product, main_formulation: Formulation }) {

    const foodIngredients = props.main_formulation.contents?.map((ingredient: MaterialEntity) => ingredient.name);

    const {
        isLoading: isLoadingFormulationCompositionSummaryData,
        data: formulationCompositionSummaryData
    } = useQuery(
        {
            // @ts-ignore
            ...formulationCompositionSummaryPageQuery(props.main_formulation.id),
        }
    )

    return (
        <Grid2 container
               justifyContent={"flex-start"}
               alignItems={"flex-start"}
               spacing={2}>
            <Grid2 size={{xs: 4, lg: 4}}>
                <Stack direction="column" spacing={2}>
                    <Paper sx={{minHeight: "200px", alignItems: "center", justifyContent: "center", display: "flex"}}>
                        {props.food.img ?
                            <img
                                src={props.food.img}
                                alt={props.food.name}
                                height={"200px"}
                            /> :
                            <img
                                src={imageFallBacks.foodproduct}
                                alt={props.food.name}
                                height={"75px"}
                            />
                        }
                    </Paper>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <CalendarToday color={"disabled"}/>
                        <Typography sx={{paddingLeft: 1}} variant="body2" color="textDisabled">
                            {fDate(props.food.date_created)}
                        </Typography>
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <MonetizationOnOutlined color={"disabled"}/>
                        <Typography sx={{paddingLeft: 1}} variant="body2" color="textDisabled">
                            {props.main_formulation.price ? `${props.main_formulation.price}${props.main_formulation.price_unit}` : "Cost not available"}
                        </Typography>
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <Extension color={"disabled"}/>
                        <ChipStack stringArray={foodIngredients}/>
                    </Stack>
                </Stack>
            </Grid2>
            <Grid2 size={{xs: 8, lg: 8}}>
                <Typography component={"h3"} variant={"h4"}>
                    About
                </Typography>
                {
                    props.food.description ?
                        <Typography gutterBottom>
                            {props.food.description}
                        </Typography> :
                        <Typography gutterBottom color={"textDisabled"}>
                            No description available
                        </Typography>
                }
            </Grid2>
            <Grid2 size={12}>
                <Typography component={"h2"} variant={"h4"}>
                    Main Formulation Overview
                </Typography>
            </Grid2>
            <Grid2 size={{xs: 12, lg: 12}}>
                <Typography variant={"h6"} align={'center'}>
                    Calories
                </Typography>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    {formulationCompositionSummaryData ?
                        formulationCompositionSummaryData?.nutrition?.enerc_kcal ?
                            <DailyPercentageIntakeWheelWithLabel
                                value={formulationCompositionSummaryData?.nutrition.enerc_kcal}
                                reference={nutritionConfig.adultDailyCalorieIntake}
                                size={100}
                                unit={'kcal/100g'}/>
                            : <ScoreAvatar score={"?"}/>
                        :
                        <ScoreAvatar score={"?"}/>
                    }
                </Box>
            </Grid2>
        </Grid2>
    )
}