// react-router
import {Link as RouterLink, useNavigate} from "react-router-dom";
// @mui
import {IconButton, Stack, Tooltip} from "@mui/material";
import {Clear, ExtensionOutlined, MenuBook} from "@mui/icons-material";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import openAPIGeneratorMaterialLibraryInstance from "../../openAPIGeneratorMaterialLibraryInstance";
import {apiRoutes} from "../../config";
import React from "react";

// ----------------------------------------------------------------------

export function IngredientDetailButton(
    props: {
        foodName?: any,
        ingredientUID: any,
        ingredientName?: any,
        ingredientType?: any,
        contentUID?: any
    }
) {
    return <Tooltip title={"See Ingredient"}>
        <IconButton component={RouterLink} to={`/ingredients/${props.ingredientUID}`}>
            <ExtensionOutlined color={"success"}></ExtensionOutlined>
        </IconButton>
    </Tooltip>
}

export function IngredientAlternativeButton(
    props: {
        foodName: any,
        ingredientUID?: any,
        ingredientName: any,
        ingredientType?: any,
        contentUID?: any
    }
) {
    const navigate = useNavigate();
    const handleAlternatives = () => {
        navigate(`/ingredients/${props.ingredientUID}/alternatives`)
    }

    return <Tooltip title={"Alternatives"}>
        <IconButton onClick={handleAlternatives}>
            <MenuBook></MenuBook>
        </IconButton>
    </Tooltip>
}

export function IngredientRemoveButton(
    props: {
        foodName?: any,
        ingredientUID?: any,
        ingredientName?: any,
        ingredientType?: any,
        contentUID: any
    }
) {
    const queryClient = useQueryClient()

    const foodContentMutation: any = useMutation({
        mutationFn: (uid: number) => {
            return openAPIGeneratorMaterialLibraryInstance.materialLibraryMaterialEntityContainsMaterialEntityDestroy(uid)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [apiRoutes.materialLibrary.materialEntity.baseEndpoint]})
        },
    })

    const handleIngredientRemoval = () => {
        foodContentMutation.mutate(props.contentUID)
    }

    return <Tooltip title={"Remove Ingredient"}>
        <IconButton onClick={handleIngredientRemoval}>
            <Clear color={"error"}></Clear>
        </IconButton>
    </Tooltip>
}

// @ts-ignore
export default function MRTButtonStack({children}) {
    return (
        <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={1}>
            {children}
        </Stack>
    )
}