import React from "react";

export function formatGrams(value?: React.ReactNode) {
    return (value !== null) && (value !== undefined) ? `${value} g` : "Unknown"
}

export function formatKcal(value?: React.ReactNode) {
    return (value !== null) && (value !== undefined) ? `${value} Kcal` : "Unknown"
}

export function formatKj(value?: React.ReactNode) {
    return (value !== null) && (value !== undefined) ? `${value} Kj` : "Unknown"
}