import React from "react";
// @mui/material
import {Card, CardContent, CardHeader, Grid2, Stack,} from "@mui/material";
// material react table
import {useQuery} from "@tanstack/react-query";
import {annotationTypes, apiRoutes} from "../../../config";
import {useParams} from "react-router-dom";
import {foodMainFormulationDetailPageQuery} from "../../../pages/FoodDetailPage";
import {AnnotationTypeBreakdown} from "../../../components/insights/AnnotationTypeBreakdown";
import {Info} from "@mui/icons-material";
import {DataLoadingStatusHandler} from "../../../components/data-loading-status-handler";
import openAPIGeneratorFoodDesignerInstance from "../../../openAPIGeneratorFoodDesignerInstance";


export const ingredientInsightsQuery = (
    materialEntity: number,
) => ({
    queryKey: [apiRoutes.foodDesigner.formulations.baseEndpoint, materialEntity, "insights"],
    queryFn: () => {
        return openAPIGeneratorFoodDesignerInstance
            .foodDesignerFormulationsAnnotationSummaryRetrieve(materialEntity)
            .then(response => response.data);
    },
});


// ----------------------------------------------------------------------
export default function FoodInsightsSection() {
    let {foodId} = useParams();

    const {isLoading: isLoadingFoodData, data: foodProductData} = useQuery(
        foodMainFormulationDetailPageQuery(Number(foodId))
    )

    const formulationId = foodProductData?.id

    const {
        status: ingredientStatus,
        data: ingredientsData,
    } = useQuery(
        // @ts-ignore
        ingredientInsightsQuery(formulationId)
    );


    return (
        <Grid2 container spacing={3}>
            <Grid2 size={12}>
                <Card>
                    <CardHeader
                        title={"What is the insights panel?"}
                        avatar={
                            <Info/>
                        }
                    />
                    <CardContent>
                        This panel includes a summary of all labels from all ingredients and sub ingredients (2 levels)
                        in a easily searchable format.
                        Currently this panel does not interact with quantity information of this ingredients in order to
                        evaluate how relevant the label is for the product.
                        For example if a sweet compound will make the food product sweet.
                    </CardContent>
                </Card>
            </Grid2>
            <Grid2 size={12}>
                <DataLoadingStatusHandler status={ingredientStatus} successRender={
                    <Stack
                        direction="column"
                    >
                        <AnnotationTypeBreakdown name={annotationTypes["TST"]}
                                                 annotations={ingredientsData?.["TST"] ?? []}></AnnotationTypeBreakdown>
                        <AnnotationTypeBreakdown name={annotationTypes["ODO"]}
                                                 annotations={ingredientsData?.["ODO"] ?? []}></AnnotationTypeBreakdown>
                        <AnnotationTypeBreakdown name={annotationTypes["TEC"]}
                                                 annotations={ingredientsData?.["TEC"] ?? []}></AnnotationTypeBreakdown>
                        <AnnotationTypeBreakdown name={annotationTypes["REG"]}
                                                 annotations={ingredientsData?.["REG"] ?? []}></AnnotationTypeBreakdown>
                    </Stack>
                }/>
            </Grid2>
        </Grid2>
    )
}


