import {Helmet} from "react-helmet-async";
// @mui
import {Button, Grid2} from "@mui/material";
// sections and components
import {NewIngredientDescription} from "../sections/@dashboard/ingredients/new-ingredients";
import {useNavigate} from "react-router-dom";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {apiRoutes} from "../config";
import OpenAPIGeneratorMaterialLibraryInstance from "../openAPIGeneratorMaterialLibraryInstance";
import {MaterialEntity} from "../api";
import {SubmitHandler, useForm} from "react-hook-form";


export default function NewIngredientPage() {
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const {
        control,
        handleSubmit,
    } = useForm<MaterialEntity>(
        {
            defaultValues: {
                name: "",
                description: "",
                type: "CPX"
            }
        }
    )


    const createIngredientMutation = useMutation({
        mutationFn: (data: MaterialEntity) => {
            return OpenAPIGeneratorMaterialLibraryInstance.materialLibraryMaterialEntitiesCreate(data)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [apiRoutes.materialLibrary.materialEntity.baseEndpoint]})
        },
    })


    const onSubmit: SubmitHandler<MaterialEntity> = async (data) => {
        await createIngredientMutation.mutateAsync(data).then((value) =>
            navigate(`/ingredients/${value.data.id}`)
        ).catch(
            (reason) => navigate(`/ingredients/`)
        )
    }
    return (
        <>
            <Helmet>
                <title> New Ingredient | CibusAI </title>
            </Helmet>
            <Grid2 container spacing={2}>
                <Grid2 size={12}>
                    <NewIngredientDescription control={control}/>
                </Grid2>
                <Grid2 size={12}>
                    <Button variant={'outlined'}
                            size={'large'}
                            color={'success'}
                            type={'submit'}
                            onClick={handleSubmit(onSubmit)}>
                        Submit
                    </Button>
                </Grid2>
            </Grid2>
        </>
    )
}