import React, {ChangeEvent, useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {Box, Button, Divider, Grid2, InputAdornment, Skeleton, Stack, Tab, Tabs, Typography} from '@mui/material';
import {Add, FileUploadOutlined, Search} from "@mui/icons-material";
import {
    FilterIngredientCardList,
    SortIngredientCardList,
    TypeIngredientCardList
} from "../sections/@dashboard/ingredients";
import {IngredientCard} from "../components/ingredient-card";
import {StyledSearch} from "../components/search";
import {QueryClient, useQuery} from "@tanstack/react-query";
import {apiRoutes, ingredientTypesOptions} from "../config";
import Pagination from "@mui/material/Pagination";
import {SortOption, SortOptionCreateParams} from "../utils/filteringAndSorting";
import openAPIGeneratorMaterialLibraryInstance from "../openAPIGeneratorMaterialLibraryInstance";
import {NavLink as RouterLink, useSearchParams} from "react-router-dom";
import {MaterialEntity} from "../api";
import {
    ClearFilterIngredientCardList
} from "../sections/@dashboard/ingredients/ingredient-list-filters/FilterIngredientCardList";
import CreatorIngredientCardList, {
    CreatorOption
} from "../sections/@dashboard/ingredients/ingredient-list-filters/CreatorIngredientCardList";
import {DataLoadingStatusHandler} from "../components/data-loading-status-handler";
import FilterBar from "../components/filter-bar";
import {SCOPES} from "../access/permission-maps";
import PermissionsGate from "../access/PermissionsGate";

// ---------------------------------------------------------------------------------------------------------------------

export const sortInitialState: SortOption = {
    property: 'none',
    order: 'desc',
    label: 'None'
};

export const creatorInitialState: CreatorOption = {
    value: undefined, label: 'None'
};

export const ingredientsPageQuery = (
    annotationsValue?: string[],
    cibus_id?: boolean,
    searchQuery?: string,
    sort?: SortOption,
    page?: number,
    pageSize?: number,
    type?: string[]
) => ({
    queryKey: [apiRoutes.materialLibrary.materialEntity.baseEndpoint, page, pageSize, searchQuery, sort, type, annotationsValue, cibus_id],
    queryFn: () => {
        return openAPIGeneratorMaterialLibraryInstance
            .materialLibraryMaterialEntitiesList(annotationsValue, cibus_id, undefined, page, pageSize, searchQuery, type)
            .then(response => response.data);
    },
    initialData: {
        "count": 0,
        "next": null,
        "previous": null,
        "results": []
    }
});

export const ingredientsPageLoader = async (queryClient: QueryClient) => {
    // @ts-ignore
    const query = ingredientsPageQuery(undefined, undefined, undefined, sortInitialState, 1, 9, undefined);
    return queryClient.ensureQueryData(query);
};

export const annotationLabelsPageQuery = () => ({
    queryKey: [apiRoutes.materialLibrary.materialEntity.detail.annotationLabels],
    queryFn: () => {
        return openAPIGeneratorMaterialLibraryInstance
            .materialLibraryMaterialEntitiesAnnotationLabelsRetrieve()
            .then(response => response.data);
    },
});

// ---------------------------------------------------------------------------------------------------------------------

export const FilterTopicTypographyStyle = {
    marginTop: 1,
    mb: 1,
    fontWeight: "bold",
    letterSpacing: "0.5px",
    fontSize: "14px",
}


function AnnotationFilter(props: {
    title: string,
    allFilters: string[],
    filters: string[],
    handleFiltersChange: any,
    isLoading?: boolean,
}) {
    return (
        <>
            <Typography
                gutterBottom
                sx={FilterTopicTypographyStyle}
            >
                {props.title}
            </Typography>
            <Box sx={{paddingTop: '12px'}}>
                {
                    props.isLoading ?
                        <Skeleton/>
                        :
                        <FilterIngredientCardList value={props.filters}
                                                  setValue={props.handleFiltersChange}
                                                  allFilters={props.allFilters}/>
                }
            </Box>
        </>
    )
}


export function IngredientFilters(props: {
    creator: CreatorOption,
    handleCreatorChange: React.Dispatch<React.SetStateAction<CreatorOption>>,
    sort: SortOption,
    handleSortChange: React.Dispatch<React.SetStateAction<SortOption>>,
    type: string[],
    handleTypeChange: React.Dispatch<React.SetStateAction<string[]>>,
    filters: string[],
    handleFiltersChange: React.Dispatch<React.SetStateAction<string[]>>
}) {
    const [tabIndex, setTabIndex] = useState<number>(0);
    const sortOptions: SortOption[] = [
        {property: 'none', order: "asc", label: 'None'},
        {property: 'date', order: "desc", label: 'Newest'},
        {property: 'date', order: "asc", label: 'Oldest'},
        {property: 'name', order: "desc", label: 'Desc: Name'},
        {property: 'name', order: "asc", label: 'Asc: Name'},
    ];

    const creatorOptions = [
        {value: undefined, label: 'All'},
        {value: false, label: 'Cibus Data Service'},
        {value: true, label: 'User Submitted'},
    ];

    const {data: ingredientsAnnotationData, isLoading: ingredientsAnnotationDataIsLoading} = useQuery(
        annotationLabelsPageQuery()
    );

    // Handler for changing tabs
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    return <>
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="filter-tabs">
            <Tab label="Main"/>
            <Tab label="Annotation"/>
            <Tab label="Usage & IDs"/>
        </Tabs>
        <Divider/>
        <TabPanel value={tabIndex} index={0}>
            <Typography
                gutterBottom
                sx={FilterTopicTypographyStyle}
            >
                Origin
            </Typography>
            <CreatorIngredientCardList value={props.creator} setValue={props.handleCreatorChange}
                                       sortOptions={creatorOptions}/>
            <Typography
                gutterBottom
                sx={FilterTopicTypographyStyle}
            >
                Sort By
            </Typography>
            <SortIngredientCardList value={props.sort} setValue={props.handleSortChange}
                                    sortOptions={sortOptions}/>
            <Typography
                gutterBottom
                sx={FilterTopicTypographyStyle}
            >
                Type
            </Typography>
            <TypeIngredientCardList
                selectedType={props.type}
                ingredientTypes={ingredientTypesOptions}
                setSelectedType={props.handleTypeChange}
            />
            {/*@ts-ignore*/}
            <ClearFilterIngredientCardList value={props.filters} setValue={props.handleFiltersChange}/>
        </TabPanel>

        <TabPanel value={tabIndex} index={1}>
            <AnnotationFilter title={"Taste"}
                              allFilters={ingredientsAnnotationData?.TST ?? []}
                              isLoading={ingredientsAnnotationDataIsLoading}
                              filters={props.filters}
                              handleFiltersChange={props.handleFiltersChange}
            />
            <AnnotationFilter title={"Odor"}
                              allFilters={ingredientsAnnotationData?.ODO ?? []}
                              isLoading={ingredientsAnnotationDataIsLoading}
                              filters={props.filters}
                              handleFiltersChange={props.handleFiltersChange}
            />
            {/*@ts-ignore*/}
            <ClearFilterIngredientCardList value={props.filters} setValue={props.handleFiltersChange}/>
        </TabPanel>

        <TabPanel value={tabIndex} index={2}>
            <AnnotationFilter title={"Technical Effect"}
                              allFilters={ingredientsAnnotationData?.TEC ?? []}
                              isLoading={ingredientsAnnotationDataIsLoading}
                              filters={props.filters}
                              handleFiltersChange={props.handleFiltersChange}
            />
            <AnnotationFilter title={"Regulatory"}
                              allFilters={ingredientsAnnotationData?.REG ?? []}
                              isLoading={ingredientsAnnotationDataIsLoading}
                              filters={props.filters}
                              handleFiltersChange={props.handleFiltersChange}
            />
            <AnnotationFilter title={"External Identifiers"}
                              allFilters={ingredientsAnnotationData?.EXT ?? []}
                              isLoading={ingredientsAnnotationDataIsLoading}
                              filters={props.filters}
                              handleFiltersChange={props.handleFiltersChange}
            />
            {/*@ts-ignore*/}
            <ClearFilterIngredientCardList value={props.filters} setValue={props.handleFiltersChange}/>
        </TabPanel>
    </>
}

export default function IngredientsPage() {
    const [searchParams, setSearchParams] = useSearchParams();


    const [filters, setFilters] = useState<string[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>(searchParams.get("search") ?? ""); // Updated to string
    const [sort, setSort] = useState<SortOption>(sortInitialState);
    const [creator, setCreator] = useState<CreatorOption>(creatorInitialState);
    const [page, setPage] = useState<number>(1);
    const pageSize = 9;
    const [type, setType] = useState<string[]>([]);

    const handleQueryByKey = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearchQuery(event.target.value);
        setSearchParams(event.target.value ? {"search": event.target.value.toString()} : undefined)
    };

    // Handler for changing pages
    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number): void => {
        setPage(page);
    };

    // Effect to reset the page to 1 whenever search query, type, annotations, or sorting changes
    useEffect(() => {
        setPage(1);
    }, [filters, searchQuery, sort, pageSize, type]);

    const {
        data: ingredientsData,
        status: ingredientStatus
    } = useQuery(
        ingredientsPageQuery(filters, creator.value, searchQuery, sort, page, pageSize, type)
    );

    return (
        <>
            <Helmet>
                <title>Ingredients | CibusAI</title>
            </Helmet>

            {/* Main content container */}
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
            }}>
                {/* Sidebar with Tabs */}
                <FilterBar>
                    <IngredientFilters creator={creator}
                                       handleCreatorChange={setCreator}
                                       type={type}
                                       handleTypeChange={setType}
                                       sort={sort}
                                       handleSortChange={setSort}
                                       filters={filters}
                                       handleFiltersChange={setFilters}
                    />
                </FilterBar>

                {/* Main Content Section */}
                <Box>
                    <Grid2 container
                           alignItems="center"
                           justifyContent="space-between"
                           spacing={2}>
                        <Grid2 size={{xs: 12, md: 6}}>
                            <StyledSearch
                                fontSize={16}
                                width={400}
                                value={searchQuery}
                                onChange={handleQueryByKey}
                                placeholder="Search Ingredient..."
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Search/>
                                    </InputAdornment>
                                }
                            />
                        </Grid2>
                        <PermissionsGate fallback={<></>} scopes={[SCOPES.ingredientsCanCreate]}>

                            <Grid2 size={{xs: 12, md: 6}}>
                                <Stack direction="row"
                                       justifyContent={"flex-end"}
                                       spacing={2}>
                                    <Button variant="outlined"
                                            size={"large"}
                                            color={"success"}
                                            disabled
                                            startIcon={<FileUploadOutlined/>}>
                                        Bulk Import
                                    </Button>
                                    <Button variant="outlined"
                                            size={"large"}
                                            startIcon={<Add/>}
                                            component={RouterLink}
                                            to={"new-ingredient"}>
                                        New Ingredient
                                    </Button>
                                </Stack>
                            </Grid2>
                        </PermissionsGate>
                        <Grid2 size={12}>
                            <DataLoadingStatusHandler
                                status={ingredientStatus}
                                count={ingredientsData.count}
                                successRender={
                                    <Grid2 container spacing={3} size={12}>
                                        {ingredientsData.results.map((ingredient: MaterialEntity) => (
                                            <Grid2 size={{xs: 12, md: 6, lg: 4}} key={ingredient.id}>
                                                <IngredientCard ingredient={ingredient}/>
                                            </Grid2>))}
                                        <Grid2 size={12} alignItems={"center"}>
                                            <Stack alignItems={"center"}>
                                                <Pagination
                                                    variant="outlined"
                                                    shape="rounded"
                                                    count={Math.ceil(ingredientsData ? ingredientsData.count / pageSize : 0)}
                                                    page={page}
                                                    onChange={handlePageChange}
                                                />
                                            </Stack>
                                        </Grid2>
                                    </Grid2>
                                }
                            />
                        </Grid2>
                    </Grid2>
                </Box>
            </Box>
        </>
    )
        ;
}

// TabPanel Component
function TabPanel(props: {
    children?: React.ReactNode;
    index: number;
    value: number
}) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 2}}>
                    {children}
                </Box>
            )}
        </div>
    );
}
