import {useState} from 'react';
// @mui
import {Button, Menu, MenuItem, Typography} from '@mui/material';
import {ArrowDropDown, ArrowDropUp} from "@mui/icons-material";
import {SortOption} from "../../../utils/filteringAndSorting";

// ----------------------------------------------------------------------

export default function SortFoodCardList(props: {value: any, setValue: any, sortOptions: SortOption[]}) {
    const [open, setOpen] = useState(null);

    const handleOpen = (event: any) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleSelect = (option: any) => {
        props.setValue(option);
        setOpen(null);
    }

    return (
        <>
            <Button
                size={"large"}
                color="inherit"
                disableRipple
                onClick={handleOpen}
                endIcon={open ? <ArrowDropUp fontSize="small"/> : <ArrowDropDown fontSize="small"/>}
            >
                Sort By:&nbsp;
                <Typography component="span" variant="subtitle2" sx={{color: 'textSecondary'}}>
                    {props.value.label}
                </Typography>
            </Button>
            <Menu
                keepMounted
                anchorEl={open}
                open={Boolean(open)}
                onClose={handleClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
            >
                {props.sortOptions.map((option: any) => (
                    <MenuItem
                        key={option.value}
                        selected={option.value === 'none'}
                        onClick={() => handleSelect(option)}
                        sx={{typography: 'body2'}}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
