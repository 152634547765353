import {Card, CardContent, CardHeader, IconButton, Stack, TextField} from '@mui/material';
import React, {useState} from "react";
import SearchIcon from '@mui/icons-material/Search';
import {useNavigate} from "react-router-dom";

export default function OverviewSearch(props: { name: string, description: string, section: string }) {
    const [searchQuery, setSearchQuery] = useState("");
    const navigate = useNavigate();

    const onSearch = () => {
        navigate(`/${props.section}/?search=` + searchQuery)
    }

    return (
        <Card>
            <CardHeader title={props.name} subheader={props.description}/>

            <CardContent>
                <Stack direction={"row"} spacing={2}>
                    <TextField
                        fullWidth
                        value={searchQuery}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setSearchQuery(event.target.value);
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter')
                                onSearch()
                        }}/>
                    <IconButton aria-label="search" href={`/${props.section}/?search=` + searchQuery}>
                        <SearchIcon/>
                    </IconButton>
                </Stack>
            </CardContent>
        </Card>
    );
}
