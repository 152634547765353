import {darken, lighten} from "@mui/system";
import {alpha} from "@mui/material/styles";

export function mrtTheme(theme: any) {
    const baseBackgroundColor =
        (theme.palette.mode === 'dark'
            ? lighten(theme.palette.background.paper, 0.05)
            : theme.palette.background.paper);
    return {
        baseBackgroundColor,
        draggingBorderColor: theme.palette.primary.main,
        matchHighlightColor:
            theme.palette.mode === 'dark'
                ? darken(theme.palette.warning.dark, 0.25)
                : lighten(theme.palette.warning.light, 0.5),
        menuBackgroundColor: lighten(baseBackgroundColor, 0.07),
        pinnedRowBackgroundColor: alpha(theme.palette.primary.main, 0.1),
        selectedRowBackgroundColor: alpha(theme.palette.primary.main, 0.2),
    };
}