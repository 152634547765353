import {Grid2, MenuItem, TextField, Typography} from "@mui/material";
import React from "react";
import {Controller} from "react-hook-form";
import {ingredientTypesOptions} from "../../../../config";

export default function NewIngredientDescription(props: { control: any }) {

    return (
        <Grid2 container spacing={2}>
            <Grid2 size={12}>
                <Typography variant={"h3"}>New Ingredient Details</Typography>
            </Grid2>
            <Grid2 size={3}>
                <Controller
                    name="name"
                    control={props.control}
                    render={({field}) =>
                        <TextField
                            variant={"outlined"}
                            label={'Name'}
                            fullWidth
                            {...field} />}
                />
            </Grid2>
            <Grid2 size={3}>
                <Controller
                    name="type"
                    control={props.control}
                    render={({field}) => <TextField
                        variant="outlined"
                        label={"Ingredient Type"}
                        select
                        fullWidth
                        disabled
                        multiline
                        maxRows={8}
                        {...field}>
                        {ingredientTypesOptions.map((item, index) => (
                            <MenuItem key={index} value={item.value}>
                                {item.label}
                            </MenuItem>
                        ))}
                    </TextField>}
                />
            </Grid2>
            <Grid2 size={8}>
                <Controller
                    name="description"
                    control={props.control}
                    render={({field}) =>
                        <TextField variant={"outlined"}
                                   fullWidth
                                   multiline
                                   label={'Description'}
                                   minRows={3}
                                   {...field} />}
                />
            </Grid2>
        </Grid2>
    )
}