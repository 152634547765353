import React from 'react';
import {Box, Chip} from '@mui/material';

// ----------------------------------------------------------------------

export default function TypeIngredientCardList(
    props: {
        selectedType: string[];  // Updated to string[] | undefined for multiple selections
        setSelectedType: React.Dispatch<React.SetStateAction<string[]>>; // Updated setter to support array
        ingredientTypes: { label: string, value: string }[]; // Array with label and value for displaying
    }
) {
    // Handle selecting and deselecting types
    const handleSelect = (value: string) => {
        let selectedTypesArray = props.selectedType || []; // Fallback to empty array if undefined

        if (selectedTypesArray.includes(value)) {
            // If the type is already selected, remove it
            const updatedArray = selectedTypesArray.filter((selected) => selected !== value);
            props.setSelectedType(updatedArray.length > 0 ? updatedArray : []); // Set to undefined if the array is empty
        } else {
            // If the type is not selected, add it
            const updatedArray = [...selectedTypesArray, value];
            props.setSelectedType(updatedArray);
        }
    };

    // Check if any type is selected, fallback to empty array if undefined
    const selectedTypesArray = props.selectedType || [];

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column', // Stack chips in a vertical list style
                gap: 1, // Space between chips
                mt: 2,
                maxHeight: 300,
                overflowY: 'auto', // Enable vertical scrolling if needed
            }}
        >
            {props.ingredientTypes.map((type) => (
                <Chip
                    key={type.value} // Use the unique value as the key
                    label={type.label} // Display the label
                    clickable
                    color={selectedTypesArray.includes(type.value) ? 'primary' : 'default'} // Select based on value
                    onClick={() => handleSelect(type.value)} // Use value for selection logic
                    sx={{
                        fontSize: '14px',
                        cursor: 'pointer',
                        borderRadius: '16px',
                        width: '60%', // Make each chip take 50% width
                        padding: '8px',
                        textAlign: 'center', // Center the text
                        justifyContent: 'center', // Center the content horizontally
                    }}
                />
            ))}
        </Box>
    );
}
