import React, {ReactNode} from "react";
import {List} from "@mui/material";

export default function DataList<Type>(props: { data?: Type[], itemRender: (item: Type, index: number) => ReactNode }) {
    // MRT like table but made for list of items
    return <List>
        {
            props.data ? props.data.map((item, index) => (
                    props.itemRender(item, index)
                ))
                : null
        }
    </List>
}