import {Helmet} from 'react-helmet-async';
// @mui
import {Box, Grid2} from '@mui/material';
import {Extension, LunchDining} from "@mui/icons-material";
// components
import OverviewCard from "../components/overview-card";
import {LatestIngredients} from "../sections/@dashboard/overview";
import {useQuery} from "@tanstack/react-query";
import {apiRoutes} from "../config";
import LatestFoodProducts from "../sections/@dashboard/overview/LatestFoodProducts";
import openAPIGeneratorCoreInstance from "../openAPIGeneratorCoreInstance";
import OverviewSearch from "../components/overview-search";
import {Overview} from "../api";

// ----------------------------------------------------------------------

export const overviewPageQuery = () => ({
    queryKey: [apiRoutes.core.overviewEndpoint],
    queryFn: async () => openAPIGeneratorCoreInstance
        .coreOverviewRetrieve()
        .then(
            response => response.data
        ),
})


export const overviewPageLoader = async (queryClient: any) => {
    const query = overviewPageQuery()
    return queryClient.ensureQueryData(query)
}


export default function OverviewPage() {
    const {isLoading, data: overviewData} = useQuery(
        overviewPageQuery()
    )

    const subValues = (material_library: Overview["material_library"]) => {
        return [
            {
                label: 'Small Molecules',
                value: material_library.count_by_type.COM  ?? 0
            },
            {
                label: 'Complex',
                value: material_library.count_by_type.CPX ?? 0
            },
            {
                label: 'Protein',
                value: material_library.count_by_type.PRO ?? 0
            },
            {
                label: 'Other',
                value: material_library.count_by_type.OTH ?? 0
            }
        ];
    }

    return (
        <>
            <Helmet>
                <title> Overview | CibusAI </title>
            </Helmet>
            <Box>
                {isLoading || !overviewData ? <></> :
                    <Grid2 container spacing={3} alignItems="stretch">
                        <Grid2 size={12}>
                            <OverviewSearch
                                name={"Ingredient Search"}
                                description={"Search for relevant ingredients, by name or description"}
                                section={"ingredients"}
                            />
                        </Grid2>
                        <Grid2 size={{xs: 12, md: 6}}>
                            <OverviewCard
                                title={'Food Products'}
                                value={overviewData.food_products.total_count}
                                // subTitle={'is the average number of formulations'}
                                // subValue={overviewData.food_products.average_formulation_count == null ? 0 : overviewData.food_products.average_formulation_count}
                                color={"error.main"}
                                sx={{height: '100%'}}
                                to={"/food-products"}
                            >
                                <LunchDining/>
                            </OverviewCard>
                        </Grid2>

                        <Grid2 size={{xs: 12, md: 6}}>
                            <OverviewCard
                                title={'Ingredients'}
                                value={overviewData.material_library.total_count}
                                // subTitle={"Material Library"}
                                // subValue={subValues(overviewData.material_library)}
                                color={"success.main"}
                                sx={{height: '100%'}}
                                to={"/ingredients"}
                            >
                                <Extension/>
                            </OverviewCard>
                        </Grid2>
                        <Grid2 size={{xs: 12, md: 6}}>
                            <LatestFoodProducts
                                name="Latest Food Products"
                                description={"Check the last 5 food products"}
                                list={overviewData.food_products.latest.slice(0, 5)}
                            />
                        </Grid2>
                        <Grid2 size={{xs: 12, md: 6}}>
                            <LatestIngredients
                                name="Latest Ingredients"
                                description={"Check the last 5 ingredients"}
                                list={overviewData.material_library.latest.slice(0, 5)}
                            />
                        </Grid2>
                    </Grid2>
                }
            </Box>
        </>
    );
}
