import {ProductTypeEnum} from "./api";

export const apiUrl = process.env.REACT_APP_BACKEND_URL ?? "http://127.0.0.1:8000/";

export const fallBackUserName = "User"

export const feedbackForm = "https://forms.office.com/pages/responsepage.aspx?id=6ON7tjWTSkmlsEuOKHBeC6svaNNYfZJKuwcZSqFv82FUMkZPWjE5SDFNUkdXUVhBVkIyQ1NDRjhXTyQlQCN0PWcu&route=shorturl"

export const apiRoutes = {
    core: {
        baseEndpoint: "core/",
        overviewEndpoint: "core/overview/",
    },
    materialLibrary: {
        baseEndpoint: "material_library/",
        overviewEndpoint: "material_library/overview/",
        materialEntity: {
            baseEndpoint: "material_library/material_entities/",
            detail: {
                baseEndpoint: (id: string) => `material_library/material_entities/${id}/`,
                literatureEndpoint: (id: string) => `material_library/material_entities/${id}/literature/`,
                annotationEndpoint: (id: string) => `material_library/material_entities/${id}/annotations/`,
                alternativesEndpoint: (id: string) => `material_library/material_entities/${id}/alternatives/`,
                annotationLabels: (id: string) => `material_library/material_entities/${id}/annotationLabels/`,
            }
        },
        compounds: {
            baseEndpoint: "material_library/compounds/",
            detail: {
                baseEndpoint: (id: string) => `material_library/compounds/${id}/`,
                literatureEndpoint: (id: string) => `material_library/compounds/${id}/literature/`,
                annotationEndpoint: (id: string) => `material_library/compounds/${id}/annotations/`,
                alternativesEndpoint: (id: string) => `material_library/compounds/${id}/alternatives/`,
            },
            searchEndpoint: "material_library/compounds/search/",
        }
    },
    foodDesigner: {
        baseEndpoint: "food_designer/",
        overviewEndpoint: "food_designer/overview/",
        products: {
            baseEndpoint: "food_designer/products/",
            detail: {
                baseEndpoint: (id: string) => `food_designer/products/${id}/`,
            }
        },
        formulations: {
            baseEndpoint: "food_designer/formulations/",
            detail: {
                baseEndpoint: (id: string) => `food_designer/formulations/${id}/`,
            }
        },
        nutritionalParameters: {
            baseEndpoint: "food_designer/nutritional_parameters/",
            detail: {
                baseEndpoint: (id: string) => `food_designer/nutritional_parameters/${id}/`,
            }
        },
        nutritionalMeasurement: {
            baseEndpoint: "food_designer/nutritional_measurement/",
            detail: {
                baseEndpoint: (id: string) => `food_designer/nutritional_measurement/${id}/`,
            }
        }
    },
    assistant: {
        baseEndpoint: "assistant/",
        conversaions: {
            baseEndpoint: "assistant/conversations/",
            detail: {
                baseEndpoint: (id: string) => `assistant/conversations/${id}/`,
                chatEndpoint: (id: string) => `assistant/conversations/${id}/chat/`,
                messagesEndpoint: (id: string) => `assistant/conversations/${id}/messages/`,
            }
        }
    }
}

export const defaultChatTools = [
    "PriceChecker",
    "MolSimilarity",
    "FuncGroups",
    "GHS_classification",
    "Query2Smiles",
    "QueryCompoundAnnotations",
    "MoleculeDescriptor",
    "Mol2Weight",
    "Mol2Formula",
    "Mol2IUPAC",
    "Mol2Inchi",
    "PatentChecker",
    "ChemicalCompoundInformation",
    "UnitedStatesRegulationsAnswer",
    "EuropeanRegulationsAnswer"
]

// https://alimentacaosaudavel.dgs.pt/activeapp2020/wp-content/uploads/2019/12/Programa-de-distribuicao-de-alimentos.pdf
export const nutritionConfig = {
    adultDailyCalorieIntake: 2263
}

export const annotationTypeSets = {
    insights: new Set(["TST", "TEX", "ODO", "HLT", "TEC", "COL"]),
    logistics: new Set(["REG", "SRC", "GEO", "LBL"]),
    hybrid: new Set(["REG", "TST", "ODO", "TEC"])
}

export const imageFallBacks = {
    foodproduct: "/assets/product_standin.svg",
    // foodproduct: "https://stslab.in/wp-content/uploads/2018/09/Food-product-testing-lab-in-coimbatore.jpg",
    // ingredient: "https://www.foodingredientfacts.org/wp-content/uploads/2016/02/AdobeStock_87648286-300x200.jpeg"
    ingredient: "/assets/product_standin.svg"
}

export const annotationTypes: Record<string, string> = {
    TST: "Taste",
    COL: "Color",
    TEC: "Technical Effect",
    PRS: "Preservative",
    HLT: "Health",
    ODO: "Odor",
    TEX: "Texture",
    REG: "Regulatory",
    LBL: 'Label',
    GEO: 'Geography',
    SRC: 'Source',
}

export const macroNutrientTypes = {
    'PRO': 'Protein',
    'CARB': 'Carbohydrate',
    "FAT": "Fat",
    "VIT": "Vitamin",
    "MIN": "Mineral",
    "NDY": "Natural dye",
    "PRE": "Preservative",
    "AOX": "Antioxidant",
    "EML": "Emulsifier",
    "ACD": "Acidulant",
    "STB": "Stabilizer",
    "FLE": "Flavor Enhancer",
    "OTH": "Other",
}

export const nutrientTypes = {
    'PRO': 'Protein',
    'CARB': 'Carbohydrate',
    "FAT": "Fat",
    "VIT": "Vitamin",
    "MIN": "Mineral",
    "OTH": "Other",
}

export const functionalTypes = {
    "MIN": "Mineral",
    "NDY": "Natural dye",
    "PRE": "Preservative",
    "AOX": "Antioxidant",
    "EML": "Emulsifier",
    "ACD": "Acidulant",
    "STB": "Stabilizer",
    "FLE": "Flavor Enhancer",
}

export const ingredientTypes = {
    'COM': 'Compounds',
    'CPX': 'Substances',
    'OTH': 'Other',
    'PRO': 'Proteins'
};

export const ingredientTypesOptions: { label: string, value: string }[] = [
    {label: 'Compounds', value: 'COM'},
    {label: 'Substances', value: 'CPX'},
    {label: 'Other', value: 'OTH'},
    {label: 'Proteins', value: 'PRO'},
];

export const ProductTypeTypesOptions: { label: string, value: ProductTypeEnum }[] = [
    {label: 'Generic', value: 'generic'},
    {label: 'Red meat and products thereof', value: 'generic-meat'},
    {label: 'Cheese', value: 'generic-cheese'},
    {label: 'Animal and vegetable fats, nuts and seeds', value: 'fats_and_seeds'},
    {label: 'Beverages', value: 'beverages'},
];

export const predictionThresholds = {
    insights: 0.75
}

export const searchResponseTime = "400ms"