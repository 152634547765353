import React, {useMemo} from "react";
// @mui
import {Chip, CircularProgress, Grid2, IconButton, Tooltip, Typography} from "@mui/material";
// components
import {ExtensionOutlined} from "@mui/icons-material";
import {annotationTypes, annotationTypeSets, apiRoutes} from "../../../config";
import {useQuery} from "@tanstack/react-query";
import {Link as RouterLink, useParams} from "react-router-dom";
import openAPIGeneratorMaterialLibraryInstance from "../../../openAPIGeneratorMaterialLibraryInstance";
import {ChipStackEvidencePrediction, getInsightsColumns} from "../foods/FoodContentSection";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import {mrtTheme} from "../../../theme/mrtTheme";
import {useTheme} from "@mui/material/styles";
import {DataLoadingStatusHandler} from "../../../components/data-loading-status-handler";
import DataOperationStatus from "../../../components/data-operation-status/DataOperationStatus";

// ----------------------------------------------------------------------


function ChipScore(props: { value: number }) {
    const rounded_value = Math.round((props.value + Number.EPSILON) * 100 * 100) / 100

    if (rounded_value > 75) {
        return (
            <Tooltip title={`High Score`} followCursor>
                <Chip label={"high"}
                      color={"success"}
                      variant={"outlined"}
                      component="a"
                      href="#"
                      size={"small"}
                />
            </Tooltip>
        )
    } else if (rounded_value > 50) {
        return (
            <Tooltip title={`Medium score`} followCursor>
                <Chip label={"medium"}
                      color={"warning"}
                      variant={"outlined"}
                      component="a"
                      href="#"
                      size={"small"}
                />
            </Tooltip>
        )
    } else {
        return (
            <Tooltip title={`Low Score`} followCursor>
                <Chip label={"low"}
                      color={"info"}
                      variant={"outlined"}
                      component="a"
                      href="#"
                      size={"small"}
                />
            </Tooltip>
        )
    }
}


export const ingredientAlternativesQuery = (ingredientId: any) => ({
        queryKey: [apiRoutes.materialLibrary.materialEntity.baseEndpoint, ingredientId, "alternatives"],
        queryFn: () => openAPIGeneratorMaterialLibraryInstance
            .materialLibraryMaterialEntitiesAlternativesRetrieve(ingredientId)
            .then(
                response => response.data
            ),
    }
)

export default function IngredientAlternatives() {
    let {ingredientId} = useParams();
    let theme = useTheme();
    const columns = useMemo(
        () => {
            return [
                {
                    accessorKey: 'name',
                    header: 'Name',
                    columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
                    size: 150,
                    Cell: (props: { renderedCellValue: any }) => <Typography
                        variant={"subtitle2"}>{props.renderedCellValue}</Typography>,
                },
                {
                    accessorKey: 'score',
                    header: 'Score',
                    Cell: (props: { renderedCellValue: any }) => <ChipScore value={props.renderedCellValue}/>,
                },
                ...[...annotationTypeSets.insights].map((key) => ({
                    accessorKey: annotationTypes[key],
                    header: annotationTypes[key],
                    columnFilterModeOptions: ['fuzzy'],
                    size: 100,
                    Cell: (props: { renderedCellValue: any }) => props.renderedCellValue ?
                        <ChipStackEvidencePrediction insightsValues={props.renderedCellValue}/> : <></>
                })),
            ]
        },
        [],
    );

    const {status: alternativeStatus, data: alternativesData} = useQuery(
        ingredientAlternativesQuery(ingredientId)
    )

    const data = useMemo(
        () => {
            return alternativesData ? alternativesData.alternatives.map((ingredient: any) => ({
                id: ingredient.id,
                name: ingredient.name,
                score: ingredient.similarity_scores.overall_score,
                ...getInsightsColumns(ingredient)
            })) : []
        },
        [alternativesData],
    );

    const table = useMaterialReactTable({
        columns: columns,
        data: data ? data : [],
        enableColumnFilterModes: true,
        enableRowActions: true,
        mrtTheme: mrtTheme(theme),
        renderRowActions: ({row}) => (
            <Tooltip title={"See Ingredient"}>
                <IconButton component={RouterLink}
                            to={`/ingredients/${row.original.id}`}>
                    <ExtensionOutlined
                        color={"success"}></ExtensionOutlined>
                </IconButton>
            </Tooltip>
        )
    });

    return (
        <Grid2 container
               direction={"row"}
               justifyContent={'flex-start'}
               alignItems={'flex-start'}>
            <Grid2 size={12}>
                <DataLoadingStatusHandler
                    status={alternativeStatus}
                    successRender={
                        <MaterialReactTable table={table}/>
                    }
                    loadingRender={
                        <DataOperationStatus
                            icon={<CircularProgress/>}>
                            <Typography color={"textSecondary"}>
                                Finding Alternatives.
                            </Typography>
                        </DataOperationStatus>
                    }
                />
            </Grid2>
        </Grid2>
    )
}


