import React, {useMemo, useState} from "react";
// @mui
import {Box, Button, Divider, Grid2, IconButton, Link, Skeleton, Stack, Tooltip, Typography} from "@mui/material";
// material react table
import {MaterialReactTable, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
// components
import {FoodInfoCard} from "../../../components/food-info-card";
// mock
import {apiRoutes, nutritionConfig} from "../../../config";
import {
    MaterialEntity,
    MaterialEntityContainsMaterialEntity,
    PaginatedMaterialEntityContainsMaterialEntityList,
    UnitEnum
} from "../../../api";
import openAPIGeneratorMaterialLibraryInstance from "../../../openAPIGeneratorMaterialLibraryInstance";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {Add, Edit} from "@mui/icons-material";
import {IngredientsModal} from "../../../components/ingredients-modal";
import MRTButtonStack, {
    IngredientAlternativeButton,
    IngredientDetailButton,
    IngredientRemoveButton
} from "../../../components/ingredient-buttons/IngredientButtons";
import {
    foodDetailPageQuery,
    foodMainFormulationDetailPageQuery,
    formulationCompositionSummaryPageQuery
} from "../../../pages/FoodDetailPage";
import {Link as RouterLink, useParams} from "react-router-dom";
import {typeToIcon} from "../../../utils/iconMaps";
import {EditFormulationMetadata} from "./EditFormulationMetadata";
import {useTheme} from "@mui/material/styles";
import {mrtTheme} from "../../../theme/mrtTheme";
import {DataLoadingStatusHandler} from "../../../components/data-loading-status-handler";

// ----------------------------------------------------------------------
export const ingredientContentsPageQuery = (
    materialEntity?: number,
    page?: number,
    pageSize?: number,
    search?: string,
) => ({
    queryKey: [apiRoutes.materialLibrary.materialEntity.baseEndpoint, materialEntity, "contents", search, page, pageSize],
    queryFn: () => {
        return openAPIGeneratorMaterialLibraryInstance
            .materialLibraryMaterialEntityContainsMaterialEntityList(undefined, materialEntity, page, pageSize, search)
            .then(response => response.data);
    },
    initialData: {
        "count": 0,
        "next": null,
        "previous": null,
        "results": []
    }
});


export function IngredientsTable(props: {
    food: MaterialEntity
    detail: boolean
    edit: boolean
    alternative: boolean
}) {
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20, //customize the default page size
    });
    const queryClient = useQueryClient()
    const [globalFilter, setGlobalFilter] = useState('');
    const theme = useTheme()

    const {
        data: ingredientsData,
        status: ingredientStatus
    } = useQuery<PaginatedMaterialEntityContainsMaterialEntityList>(
        ingredientContentsPageQuery(props.food.id, pagination.pageIndex + 1, pagination.pageSize, globalFilter)
    );

    const columns: MRT_ColumnDef<MaterialEntityContainsMaterialEntity>[] = useMemo(
        () => {
            return [
                {
                    accessorKey: 'child_material_entity.name',
                    header: 'Ingredient',
                    columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
                    size: 150,
                    enableEditing: false,
                    Cell: ({row, renderedCellValue}) => <Stack direction={"row"} alignItems="center"
                                                               justifyContent="flex-start" spacing={1}>
                        {/* @ts-ignore*/}
                        {typeToIcon[row.original.child_material_entity.type]}
                        <Link underline="hover" color="inherit" variant={"subtitle2"} component={RouterLink}
                              to={`/ingredients/${row.original.child_material_entity.id}`}>{renderedCellValue}</Link>
                    </Stack>,
                },
                {
                    accessorKey: 'alias_name',
                    header: 'Alias',
                    columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
                    size: 150,
                },
                {
                    accessorKey: 'amount',
                    header: 'Quantity',
                    size: 150,
                },
                {
                    accessorKey: 'unit',
                    header: 'Unit',
                    editVariant: "select",
                    editSelectOptions: Object.values(UnitEnum),
                    size: 150,
                },
            ]
        },
        [],
    );

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const foodContentMutation: any = useMutation({
        mutationFn: (material_entity: MaterialEntity) => {
            return openAPIGeneratorMaterialLibraryInstance.materialLibraryMaterialEntityContainsMaterialEntityCreate(
                {
                    material_entity: props.food.id,
                    child_material_entity: material_entity.id
                }
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [apiRoutes.materialLibrary.materialEntity.baseEndpoint, props.food.id]})
            queryClient.invalidateQueries({queryKey: [apiRoutes.foodDesigner.formulations.baseEndpoint, props.food.id]})
        },
    })
    const ingredientContentMutation: any = useMutation({
        mutationFn: (content: MaterialEntityContainsMaterialEntity) => {
            console.log(content)
            return openAPIGeneratorMaterialLibraryInstance.materialLibraryMaterialEntityContainsMaterialEntityPartialUpdate(
                content.id,
                {
                    amount: content.amount,
                    alias_name: content.alias_name,
                    unit: content.unit
                }
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [apiRoutes.materialLibrary.materialEntity.baseEndpoint, props.food.id]})
            queryClient.invalidateQueries({queryKey: [apiRoutes.foodDesigner.formulations.baseEndpoint, props.food.id]})
        },
    })

    const table = useMaterialReactTable({
        columns: columns,
        data: ingredientsData.results,
        enableEditing: true,
        editDisplayMode: 'row',
        onEditingRowSave: ({table, row, values}) => {
            ingredientContentMutation.mutate({...row.original, ...values});
            table.setEditingRow(null); //exit editing mode
        },
        mrtTheme: mrtTheme(theme),
        enableColumnFilterModes: true,
        enableRowActions: true,
        renderRowActions: ({row, table}) => (
            <MRTButtonStack>
                {props.detail ? <IngredientDetailButton
                    foodName={props.food.name}
                    ingredientUID={row.original.child_material_entity.id}
                    ingredientName={row.original.child_material_entity.name}
                    ingredientType={row.original.child_material_entity.type}
                    contentUID={row.original.id}
                /> : null}
                {props.alternative ? <IngredientAlternativeButton
                    foodName={props.food.name}
                    ingredientUID={row.original.child_material_entity.id}
                    ingredientName={row.original.child_material_entity.name}
                    ingredientType={row.original.child_material_entity.type}
                    contentUID={row.original.id}
                /> : null}
                {props.edit ?
                    <Tooltip title={"Edit"}>
                        <IconButton onClick={() => table.setEditingRow(row)}>
                            <Edit/>
                        </IconButton>
                    </Tooltip>
                    : null}
                {props.edit ? <IngredientRemoveButton
                    foodName={props.food.name}
                    ingredientUID={row.original.child_material_entity.id}
                    ingredientName={row.original.child_material_entity.name}
                    ingredientType={row.original.child_material_entity.type}
                    contentUID={row.original.id}
                /> : null}
            </MRTButtonStack>
        ),
        renderTopToolbarCustomActions: () => {
            return props.edit ? (
                <div>
                    <Button onClick={handleOpen}
                            variant={"outlined"}
                            startIcon={<Add/>}>
                        Add Ingredient
                    </Button>
                    <IngredientsModal setIngredient={foodContentMutation.mutate} open={open}
                                      handleClose={handleClose}/>
                </div>
            ) : null
        },
        enableDensityToggle: false,
        enableColumnFilters: false,
        enableColumnOrdering: false,
        enableFullScreenToggle: false,
        enableHiding: false,

        // Sever Side mode

        enablePagination: true,
        manualPagination: true,
        manualFiltering: true, //turn off client-side filtering
        onGlobalFilterChange: setGlobalFilter, //hoist internal global state to your state
        onPaginationChange: setPagination,
        state: {
            pagination: pagination,
            globalFilter: globalFilter
        }, //pass in your own managed globalFilter state
        rowCount: ingredientsData?.count ?? 0
    })

    return <DataLoadingStatusHandler
        status={ingredientStatus}
        successRender={<MaterialReactTable table={table}/>}
    />
}

export default function FoodDetails() {
    let {foodId} = useParams();

    const {isLoading: isLoadingFoodData, data: foodProductData} = useQuery(
        foodDetailPageQuery(Number(foodId))
    )

    const {isLoading: isLoadingFormulationData, data: formulationData} = useQuery(
        foodMainFormulationDetailPageQuery(Number(foodId))
    )

    const {
        isLoading: isLoadingFormulationCompositionSummaryData,
        data: formulationCompositionSummaryData
    } = useQuery(
        {
            // @ts-ignore
            ...formulationCompositionSummaryPageQuery(formulationData?.id),
            enabled: !!formulationData?.id
        }
    )

    const cal_vdr = (energy_kcal: number) => Math.round(energy_kcal / nutritionConfig.adultDailyCalorieIntake * 100)


    // @ts-ignore
    return (
        <Grid2 container
               columnSpacing={2}
               rowSpacing={4}
               justifyContent="flex-start"
               alignItems="flex-start">
            {formulationData && foodProductData ?
                <>
                    <Grid2 size={12}>
                        <FoodInfoCard food={foodProductData} main_formulation={formulationData}/>
                    </Grid2>
                    <Divider sx={{mb: 2}}/>
                    <Grid2 container size={12} spacing={2}>
                        <Grid2 size={12}>
                            <Typography component={"h2"} variant={"h4"}>
                                Formulation <EditFormulationMetadata food={formulationData}/>
                            </Typography>
                        </Grid2>
                        <Grid2 size={10}>
                            {/*// @ts-ignore*/}
                            <IngredientsTable food={formulationData} detail={true} alternative={true} edit={true}/>
                        </Grid2>
                        <Grid2 size={2}>
                            <Typography component={"h3"} variant={"h4"}>
                                About
                            </Typography>
                            <Stack spacing={2}>
                                {formulationData.description ?
                                    <Typography>
                                        {formulationData.description}
                                    </Typography>
                                    :
                                    <Typography variant={"body2"}>
                                        No Description.
                                    </Typography>
                                }
                                <Box>
                                    <Typography variant={"h5"}>
                                        Concentration
                                    </Typography>
                                    {formulationCompositionSummaryData ?
                                        //@ts-ignore
                                        formulationCompositionSummaryData.composition ?
                                            //@ts-ignore
                                            <Typography
                                                variant={"body2"}>{formulationCompositionSummaryData.composition.total}%
                                                accounted</Typography> :
                                            <Typography variant={"body2"}>Not enough Information</Typography> : <></>
                                    }
                                </Box>
                                <Box>
                                    <Typography variant={"h5"}>
                                        Nutrition
                                    </Typography>
                                    {formulationCompositionSummaryData ?
                                        <>
                                            <Typography variant={"h6"}>
                                                Energy: {formulationCompositionSummaryData.nutrition.enerc_kcal} kcal/
                                                100g
                                            </Typography>
                                            <Typography
                                                variant={"body2"}>
                                                ({formulationCompositionSummaryData.nutrition.enerc_kcal !== null ?
                                                cal_vdr(Number(formulationCompositionSummaryData.nutrition.enerc_kcal)) > 0 ?
                                                    cal_vdr(Number(formulationCompositionSummaryData.nutrition.enerc_kcal)) :
                                                    "Less than 1"
                                                : "not registered"
                                            }%
                                                PT VDR)
                                            </Typography>
                                            <Typography variant={"h6"}>
                                                Fat: {formulationCompositionSummaryData.nutrition.fat_g !== null ? `${formulationCompositionSummaryData.nutrition.fat_g} g/ 100g` : "not registered"}
                                            </Typography>
                                            <Typography variant={"body2"}>
                                                Saturated: {formulationCompositionSummaryData.nutrition.fasat_g !== null ? `${formulationCompositionSummaryData.nutrition.fasat_g} g/
                                                100g` : "not registered"}
                                            </Typography>
                                            <Typography variant={"h6"}>
                                                Carbohydrate: {formulationCompositionSummaryData.nutrition.cho_g !== null ? `${formulationCompositionSummaryData.nutrition.cho_g} g/
                                                100g` : "not registered"}
                                            </Typography>
                                            <Typography variant={"body2"}>
                                                Sugars: {formulationCompositionSummaryData.nutrition.sugar_g !== null ? `${formulationCompositionSummaryData.nutrition.sugar_g} g/ 100g` : "not registered"}
                                            </Typography>
                                            <Typography variant={"h6"}>
                                                Protein: {formulationCompositionSummaryData.nutrition.prot_g !== null ? `${formulationCompositionSummaryData.nutrition.prot_g} g/ 100g` : "not registered"}
                                            </Typography>
                                            <Typography variant={"h6"}>
                                                Salt: {formulationCompositionSummaryData.nutrition.nacl_g !== null ? `${formulationCompositionSummaryData.nutrition.nacl_g} g/ 100g` : "not registered"}
                                            </Typography>
                                        </>
                                        : <Skeleton/>}
                                </Box>
                            </Stack>
                        </Grid2>
                    </Grid2>
                </>
                : <></>
            }
        </Grid2>
    )
}

