import {MaterialEntity} from "../../../api";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import React, {useState} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {apiRoutes, ingredientTypesOptions} from "../../../config";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import openAPIGeneratorMaterialLibraryInstance from "../../../openAPIGeneratorMaterialLibraryInstance";
import {useNavigate} from "react-router-dom";
import {Delete} from "@mui/icons-material";

export function EditIngredientMetadata(props: { ingredient: MaterialEntity }) {
    const {ingredient} = props

    const {
        control,
        handleSubmit,
    } = useForm<MaterialEntity>(
        {
            defaultValues: {
                name: ingredient.name,
                description: ingredient.description,
                type: ingredient.type,
            },
        }
    )
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const queryClient = useQueryClient()
    const navigate = useNavigate()


    const ingredientMetadataMutation = useMutation({
        mutationFn: (data: MaterialEntity) => {
            console.log(data)
            return openAPIGeneratorMaterialLibraryInstance.materialLibraryMaterialEntitiesUpdate(ingredient.id, data)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [apiRoutes.materialLibrary.materialEntity.baseEndpoint, ingredient.id.toString()]})
        },
        onError: (error, variables, context) => {
            console.log(error, variables, context)
        }
    })

    const foodDeleteMutation: any = useMutation({
        mutationFn: () => {
            return openAPIGeneratorMaterialLibraryInstance.materialLibraryMaterialEntitiesDestroy(ingredient.id)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [apiRoutes.materialLibrary.materialEntity.baseEndpoint, ingredient.id.toString()]})
        },
    })

    const onSubmit: SubmitHandler<MaterialEntity> = (data) => {
        console.log(data)
        ingredientMetadataMutation.mutate(data)
        handleClose();
    }

    const onDelete = () => {
        foodDeleteMutation.mutate()
        handleClose();
        navigate("/ingredients")
    }

    return (
        <>
            <Button onClick={handleOpen}>Edit Ingredient</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    // @ts-ignore
                    component: "form",
                    onSubmit: handleSubmit(onSubmit),
                }}>
                <DialogTitle>Edit Ingredient Metadata</DialogTitle>
                <DialogContent>
                    <Typography variant={"h6"} gutterBottom>
                        Metadata
                    </Typography>
                    <Controller
                        name="name"
                        control={control}
                        render={({field}) =>
                            <TextField
                                variant={"outlined"}
                                label={'Name'}
                                margin={"normal"}
                                fullWidth
                                {...field} />}
                    />
                    <Controller
                        name="type"
                        control={control}
                        render={({field}) => <TextField
                            variant="outlined"
                            label={"Ingredient Type"}
                            select
                            margin={"normal"}
                            fullWidth
                            multiline
                            disabled
                            maxRows={8}
                            {...field}>
                            {ingredientTypesOptions.map((item, index) => (
                                <MenuItem key={index} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </TextField>}
                    />
                    <Controller
                        name="description"
                        control={control}
                        render={({field}) =>
                            <TextField variant={"outlined"}
                                       fullWidth
                                       multiline
                                       margin={"normal"}
                                       label={'Description'}
                                       minRows={3}
                                       {...field} />}
                    />
                    <Typography variant={"h6"} gutterBottom>
                        Delete
                    </Typography>
                    <Button color={"error"} startIcon={<Delete/>} onClick={onDelete}>
                        Delete Ingredient Permanently
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit">Save</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}