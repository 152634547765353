import {CreateFormulation, Formulation} from "../../../api";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import React, {useState} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import openAPIGeneratorFoodDesignerInstance from "../../../openAPIGeneratorFoodDesignerInstance";
import {apiRoutes} from "../../../config";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField} from "@mui/material";

export function EditFormulationMetadata(props: { food: Formulation }) {
    const {
        control,
        handleSubmit,
    } = useForm<Formulation>(
        {
            defaultValues: {
                name: props.food.name,
                description: props.food.description,
                serving: props.food.serving,
                serving_unit: props.food.serving_unit,
                price: props.food.price,
                price_unit: props.food.price_unit,
            },
        }
    )
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const queryClient = useQueryClient()


    const foodMetadataMutation: any = useMutation({
        mutationFn: (food: CreateFormulation) => {
            return openAPIGeneratorFoodDesignerInstance.foodDesignerFormulationsUpdate(props.food.id, food)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [apiRoutes.foodDesigner.formulations.baseEndpoint, props.food.id.toString()]})
        },
    })

    const onSubmit: SubmitHandler<Formulation> = (data) => {
        console.log(data)
        foodMetadataMutation.mutate(data)
        handleClose();
    }

    return (
        <>
            <Button onClick={handleOpen}>Edit Formulation</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    // @ts-ignore
                    component: "form",
                    onSubmit: handleSubmit(onSubmit),
                }}>
                <DialogTitle>Edit Formulation Metadata</DialogTitle>
                <DialogContent>
                    <Controller
                        name="serving"
                        control={control}
                        render={({field}) => <TextField
                            variant="outlined"
                            label={"Serving"}
                            fullWidth
                            margin="normal"
                            multiline
                            maxRows={8}
                            {...field} />}
                    />
                    <Controller
                        name="serving_unit"
                        control={control}
                        render={({field}) => <TextField
                            variant="outlined"
                            label={"Serving Unit"}
                            select
                            fullWidth
                            margin="normal"
                            multiline
                            maxRows={8}
                            {...field}>
                            {/*{["ml", "g"].map((v, i) => (*/}
                            {["g"].map((v, i) => (
                                <MenuItem key={i} value={v}>
                                    {v}
                                </MenuItem>
                            ))}
                        </TextField>}
                    />
                    <Controller
                        name="price"
                        control={control}
                        render={({field}) => <TextField
                            variant="outlined"
                            label={"Price"}
                            fullWidth
                            margin="normal"
                            multiline
                            maxRows={8}
                            {...field} />}
                    />
                    <Controller
                        name="price_unit"
                        control={control}
                        render={({field}) => <TextField
                            variant="outlined"
                            label={"Price Unit"}
                            select
                            fullWidth
                            margin="normal"
                            multiline
                            maxRows={8}
                            {...field}>
                            {["€"].map((v, i) => (
                                <MenuItem key={i} value={v}>
                                    {v}
                                </MenuItem>
                            ))}
                        </TextField>}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit">Save</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}