import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Dialog,
    DialogContent,
    DialogTitle,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow
} from "@mui/material";
import {annotationTypes, annotationTypeSets, apiRoutes} from "../../config";
import {groupBy} from "../../utils/grouping";
import {EvidenceTypeEnum, MaterialEntity, MaterialEntityAnnotation, MaterialEntityAnnotationTypeEnum} from "../../api";
import {uniqueAnnotationsLabels} from "../../utils/material_entities";
import {ChipEvidenceOrPrediction} from "../../sections/@dashboard/foods/FoodContentSection";
import {useState} from "react";
import {useQuery} from "@tanstack/react-query";
import openAPIGeneratorMaterialLibraryInstance from "../../openAPIGeneratorMaterialLibraryInstance";
import {Article, QuestionMark, Science} from "@mui/icons-material";
import {capitalizedStr} from "../../utils/formatText";

export default function IngredientHighlights(props: {
    ingredient: MaterialEntity,
    size?: "medium" | "small",
    align?: "inherit" | "center" | "left" | "right" | "justify"
}) {
    const [ingredientSourceOpen, setIngredientSourceOpen] = useState(false)
    type TDetailLabel = {
        ingredientId: number,
        type: MaterialEntityAnnotationTypeEnum,
        label: string,
    }

    const [detailLabel, setDetailLabel] = useState<TDetailLabel | undefined>(undefined)

    const insights = props.ingredient.annotations ? props.ingredient.annotations.filter((annotation: MaterialEntityAnnotation) => annotationTypeSets.insights.has(annotation.type)) : [];
    const insightsGroupedByType = groupBy(insights, "type")
    const rows = Object.entries(insightsGroupedByType).map(([label, annotations]) => {
        return {
            title: annotationTypes[label],
            type: label,
            // @ts-ignore
            insightsValues: uniqueAnnotationsLabels(annotations)
        }
    })

    return (
        <>
            <TableContainer sx={{pt: 2}}>
                <Table aria-label="simple table" size={props.size}>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.title}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" align={props.align} scope="row">
                                    {row.title}
                                </TableCell>
                                <TableCell component="th" align={props.align} scope="row">
                                    <Stack direction={"row"} spacing={1}>
                                        {row.insightsValues.map((insight, idx) =>
                                            <ChipEvidenceOrPrediction
                                                insight={insight}
                                                onClick={() => {
                                                    setDetailLabel({
                                                        ingredientId: props.ingredient.id,
                                                        // @ts-ignore
                                                        type: row.type,
                                                        label: insight.label!,
                                                    });
                                                    setIngredientSourceOpen(true);
                                                }}
                                                key={idx}/>
                                        )}

                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {detailLabel ?
                <IngredientAnnotationSources open={ingredientSourceOpen}
                                             ingredientId={detailLabel.ingredientId}
                                             type={detailLabel.type}
                                             label={detailLabel.label}
                                             handleClose={() => setIngredientSourceOpen(false)}/> : <></>
            }
        </>
    )
}

function IngredientAnnotationSources(props: {
    ingredientId: number,
    type: MaterialEntityAnnotationTypeEnum,
    label: string,
    open: boolean,
    handleClose: any
}) {
    const {data: annotations, isSuccess} = useQuery(
        {
            queryKey: [apiRoutes.materialLibrary.materialEntity.detail, "detail", "annotations", props.ingredientId, props.type, props.label],
            queryFn: () => {
                return openAPIGeneratorMaterialLibraryInstance
                    .materialLibraryMaterialEntitiesAnnotationsList(props.label, props.ingredientId, undefined, undefined, 100, undefined, props.type)
                    .then(response => response.data)
            },
            enabled: props.open
        }
    )

    return <Dialog open={props.open}
                   onClose={props.handleClose}
    >
        <DialogTitle>
            Sources for {annotationTypes[props.type]} {props.label}
        </DialogTitle>
        <DialogContent>
            <Stack spacing={2}>
                {isSuccess ?
                    annotations.results.map((item) =>
                        <AnnotationCard annotation={item}/>
                    )
                    : <></>
                }
            </Stack>
        </DialogContent>
    </Dialog>
}

export function AnnotationCard(props: { annotation: MaterialEntityAnnotation }) {
    return (
        <Card>
            <CardHeader
                avatar={props.annotation.evidence_type ? EvidenceTypeIcon(props.annotation.evidence_type) :
                    <QuestionMark/>}
                title={props.annotation.label}
                subheader={annotationTypes[props.annotation.type]}
            />
            <CardContent>
                <Table aria-label="annotations-properties" size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell>Source</TableCell>
                            <TableCell>{props.annotation.source}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Evidence</TableCell>
                            <TableCell>{props.annotation.evidence_type ? capitalizedStr(props.annotation.evidence_type) : ""}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </CardContent>
            <CardActions>
                <Button size="small" color="primary" disabled={!props.annotation.url}>
                    Visit Source
                </Button>
            </CardActions>
        </Card>
    )
}

export function EvidenceTypeIcon(value: EvidenceTypeEnum) {
    switch (value) {
        case 'experimental':
            return <Science/>;
        default:
            return <Article/>;
    }
}