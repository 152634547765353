// router
import {Link as RouterLink, NavLink} from 'react-router-dom';
// @mui
import {Box, Button, Card, CardHeader, Divider, Link, Stack, Typography} from '@mui/material';
import {ArrowForward} from "@mui/icons-material";
// components
import Scrollbar from '../../../components/scrollbar';
// utils
import {fDate} from "../../../utils/formatTime";
import {imageFallBacks} from "../../../config";

// ----------------------------------------------------------------------
export default function LatestFoodProducts(props: { name: any, description: any, list: any, other?: any }) {
    return (
        <Card {...props.other}>
            <CardHeader title={props.name} subheader={props.description}/>

            <Scrollbar>
                <Stack spacing={3} sx={{p: 3, pr: 0}}>
                    {props.list.map((foodProduct: any) => (
                        <FoodProductsItem key={foodProduct.id} foodProduct={foodProduct}/>
                    ))}
                </Stack>
            </Scrollbar>

            <Divider/>

            <Box sx={{p: 2, textAlign: 'right'}}>
                <Button size="small"
                        color="inherit"
                        endIcon={<ArrowForward/>}
                        component={RouterLink}
                        to="/food-products">
                    View all
                </Button>
            </Box>
        </Card>
    );
}

// ----------------------------------------------------------------------
function FoodProductsItem(props: { foodProduct: any }) {

    return (
        <Stack direction="row" alignItems="center" spacing={2}>
            <Box component="img"
                 alt={props.foodProduct.name}
                 src={props.foodProduct.img ? `${props.foodProduct.img}` : imageFallBacks.foodproduct}
                 sx={{width: 48, height: 48, borderRadius: 1.5, flexShrink: 0}}/>

            <Box sx={{minWidth: 240, flexGrow: 1}}>
                <Link color="inherit" variant="subtitle2" underline="hover" noWrap component={NavLink}
                      to={"/food-products/" + props.foodProduct.id}>
                    {props.foodProduct.name}
                </Link>

                <Typography variant="body2" sx={{color: 'textSecondary'}} noWrap>
                    {props.foodProduct.description}
                </Typography>
            </Box>

            <Typography variant="caption" sx={{pr: 3, flexShrink: 0, color: 'textSecondary'}}>
                {fDate(props.foodProduct.date)}
            </Typography>
        </Stack>
    )
}
