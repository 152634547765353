import React, {ChangeEvent, Dispatch, useState} from 'react';
// @mui
import {Box, Button, Chip, InputAdornment, Typography} from '@mui/material';
import {StyledSearch} from "../../../../components/search";
import {Search} from "@mui/icons-material";
import ChipArray from "../../../../components/chip-array";

// ---------------------------------------------------------------------------------------------------------------------

export function ClearFilterIngredientCardList(props: {
    value: string[] | undefined;
    setValue: Dispatch<React.SetStateAction<string[] | undefined>>;
}) {

    // Clear all filters by setting an empty array.
    const handleClearFilters = () => {
        props.setValue([]); // Reset the selection.
    };

    // Determine if there are any chips selected.
    const selectedChips = props.value && props.value.length > 0;
    return (
        <Box sx={{paddingTop: '12px'}}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center', // Center the button
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!selectedChips} // Disable if no chips are selected
                    onClick={handleClearFilters} // Call handleClearFilters on button click
                    sx={{
                        borderRadius: '20px',
                        padding: '10px 24px',
                        textTransform: 'none',
                        boxShadow: selectedChips
                            ? '0px 4px 12px rgba(0, 0, 0, 0.2)' // Add a shadow if enabled
                            : 'none',
                        transition: 'all 0.3s ease',
                        ':hover': {
                            backgroundColor: selectedChips ? 'primary.dark' : 'primary.main',
                        },
                    }}
                >
                    Clear Filters
                </Button>
            </Box>
        </Box>
    );
}

export default function FilterIngredientChipList(props: {
    value: string[];
    setValue: Dispatch<React.SetStateAction<string[] | undefined>>;
    allFilters: string[];
}) {
    const [searchQuery, setSearchQuery] = useState("");
    const [annotations, setAnnotations] = useState(props.allFilters);

    // Handle selecting/deselecting a filter when the user clicks on a chip.
    const handleSelect = (filter: string) => {
        const currentSelections = props.value || [];

        // Toggle the filter in the value array.
        if (currentSelections.includes(filter)) {
            // Remove the filter if it was already selected.
            const newSelections = currentSelections.filter(item => item !== filter);
            props.setValue(newSelections);// Update the parent component's state.
        } else {
            // Add the filter if it was not selected.
            const newSelections = [...currentSelections, filter];
            props.setValue(newSelections); // Update the parent component's state.
        }
    };

    const handleQueryByKey = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearchQuery(event.target.value);
        setAnnotations(props.allFilters?.filter((annotation: string) => annotation.toLowerCase().includes(event.target.value.toLowerCase())))
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap', // Chips will wrap into a new row if the space is filled
                gap: '8px', // Space between chips
                marginBottom: '16px', // Add space between the chips and the button
            }}
        >
            {
                annotations.length > 0 ?
                    <>
                        <StyledSearch
                            fontSize={12}
                            value={searchQuery}
                            onChange={handleQueryByKey}
                            placeholder="Search Label..."
                            size="small"
                            startAdornment={
                                <InputAdornment position="start">
                                    <Search/>
                                </InputAdornment>
                            }
                        />
                        <ChipArray maxItems={10}>
                            {annotations.map((filter: string, index: number) => (
                                    <Chip
                                        label={filter}
                                        clickable
                                        color={props.value?.includes(filter) ? 'primary' : 'default'}
                                        onClick={() => handleSelect(filter)} // Call handleSelect when chip is clicked
                                        size="small" // Smaller chips
                                    />
                                )
                            )}
                        </ChipArray>
                    </> :
                    <Typography
                        variant={"caption"}
                        color={'textSecondary'}
                    >
                        No options available
                    </Typography>
            }
        </Box>
    )
        ;
}

