// react-router
import {Link as RouterLink} from 'react-router-dom';
// @msal and authentication
import {navConfig, navCreateConfig} from "./config";
import {useMsal} from "@azure/msal-react";
// @mui
import {Box, Dialog, DialogContent, DialogTitle, IconButton, Tooltip} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import * as React from 'react';
import {CSSObject, styled, Theme, useTheme} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {AddCircleOutline} from "@mui/icons-material";
import PermissionsGate from "../../../access/PermissionsGate";
import {SCOPES} from "../../../access/permission-maps";

// ----------------------------------------------------------------------

export function LogoutButton(props: any) {
    const {instance} = useMsal();

    const handleLogout = () => {
        instance.logoutPopup({
            postLogoutRedirectUri: "/",
            mainWindowRedirectUri: "/",
        }).then(() => {
            console.log("logout success");
        }).catch(e => {
            console.log(e);
        });
    }

    return (
        <IconButton
            {...props}
            type="submit"
            to={'/login'}
            variant="outlined"
            color="error"
            onClick={handleLogout}
            component={RouterLink}>
            <LogoutIcon/>
        </IconButton>
    )
}

const drawerWidthNav = 200;

export const openedMixin = (theme: Theme): CSSObject => ({
    background: theme.palette.background.default,
    width: drawerWidthNav,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    background: theme.palette.background.default,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidthNav,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function Nav() {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [addPanelOpen, setAddPanelOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleAddPanelOpen = () => {
        setAddPanelOpen(true);
    };

    const handleAddPanelClose = () => {
        setAddPanelOpen(false);
    };

    return (
        <Drawer
            variant="permanent"
            open={open}
            anchor="left"
        >
            <Toolbar/>
            <List>
                <PermissionsGate fallback={<></>} scopes={[SCOPES.ingredientsCanCreate, SCOPES.productsCanCreate]}>

                    <ListItem key={"add"} disablePadding sx={{display: 'block'}}>
                        <ListItemButton
                            onClick={handleAddPanelOpen}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <AddCircleOutline color={"primary"}/>
                            </ListItemIcon>
                            <ListItemText primary={"New"} sx={{opacity: open ? 1 : 0}}/>
                        </ListItemButton>
                    </ListItem>
                    <Dialog open={addPanelOpen} onClose={handleAddPanelClose}>
                        <DialogTitle>Actions</DialogTitle>
                        <DialogContent>
                            <List>
                                {navCreateConfig.map((item) => (
                                    <PermissionsGate fallback={<></>} scopes={item.scopes}>
                                        <ListItem key={"add" + item.title}>
                                            <ListItemButton
                                                component={RouterLink}
                                                onClick={handleAddPanelClose}
                                                to={item.path}
                                            >
                                                <ListItemIcon>
                                                    {item.icon}
                                                </ListItemIcon>
                                                <ListItemText primary={item.title}/>
                                            </ListItemButton>
                                        </ListItem>
                                    </PermissionsGate>
                                ))}
                            </List>
                        </DialogContent>
                    </Dialog>
                </PermissionsGate>
                {navConfig.map((item) => (
                    <PermissionsGate fallback={<></>} scopes={item.scopes}>
                        <ListItem key={item.title} disablePadding sx={{display: 'block'}}>
                            <Tooltip title={item.title} followCursor>
                                <ListItemButton
                                    component={RouterLink}
                                    to={item.path}
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={item.title} sx={{opacity: open ? 1 : 0}}/>
                                </ListItemButton>
                            </Tooltip>
                        </ListItem>
                    </PermissionsGate>
                ))}
            </List>
            <Box sx={{flexGrow: 1}}/>
            <List>
                <ListItem key={"Expand"} disablePadding sx={{display: 'block'}}>
                    <ListItemButton
                        onClick={open ? handleDrawerClose : handleDrawerOpen}
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            {open ? (theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>
                            ) : (
                                <MenuIcon/>
                            )}
                        </ListItemIcon>
                        <ListItemText primary={""} sx={{opacity: open ? 1 : 0}}/>
                    </ListItemButton>
                </ListItem>
            </List>
        </Drawer>
    );
}
