import React, {useMemo} from "react";
// @mui/material
import {
    Card,
    CardContent,
    CardHeader,
    Chip,
    CircularProgress,
    Grid2,
    Link,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import {Article, AutoGraph, Info, Science} from "@mui/icons-material";
// material react table
import {MaterialReactTable, MRT_ColumnDef} from "material-react-table";
import {annotationTypeSets} from "../../../config";
import {MaterialEntity, MaterialEntityAnnotation, MaterialEntityCompositionRecord} from "../../../api";
import {filter_annotations_by_type_set, uniqueAnnotationsGroupedByType} from "../../../utils/material_entities";
import {Link as RouterLink, useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {foodMainFormulationDetailPageQuery, formulationContentPageQuery} from "../../../pages/FoodDetailPage";
import {typeToIcon} from "../../../utils/iconMaps";
import {mrtTheme} from "../../../theme/mrtTheme";
import {useTheme} from "@mui/material/styles";
import DataOperationStatus from "../../../components/data-operation-status/DataOperationStatus";
import {DataLoadingStatusHandler} from "../../../components/data-loading-status-handler";


export function getLogisticsColumns(ingredient: MaterialEntity) {
    const insights = filter_annotations_by_type_set(ingredient, annotationTypeSets.logistics)
    return uniqueAnnotationsGroupedByType(insights);
}


export function ChipEvidenceOrPrediction(props: { insight: MaterialEntityAnnotation, onClick?: any }) {
    switch (props.insight.evidence_type) {
        case "experimental":
            return (
                <Tooltip title={props.insight.value} followCursor>
                    <Chip label={props.insight.label}
                          color={"success"}
                          variant={"outlined"}
                          icon={<Science/>}
                          component="a"
                          href="#"
                          size={"small"}
                          onClick={props.onClick}
                    />
                </Tooltip>
            );
        case 'prediction':
            return (
                <Tooltip title={`Predicted ${props.insight.value}`} followCursor>
                    <Chip label={props.insight.label}
                          color={"primary"}
                          variant={"outlined"}
                          icon={<AutoGraph/>}
                          component="a"
                          href="#"
                          size={"small"}
                          onClick={props.onClick}
                    />
                </Tooltip>
            );
        default:
            return (
                <Tooltip title={props.insight.value} followCursor>
                    <Chip label={props.insight.label}
                          color={"success"}
                          variant={"outlined"}
                          icon={<Article/>}
                          component="a"
                          href="#"
                          size={"small"}
                          onClick={props.onClick}
                    />
                </Tooltip>
            )
    }
}

// ----------------------------------------------------------------------
export function ChipStackEvidencePrediction(props: { insightsValues: MaterialEntityAnnotation[] }) {

    return (
        <Stack direction={'row'} spacing={1}>
            {props.insightsValues.length > 3 ?
                <>
                    <ChipEvidenceOrPrediction insight={props.insightsValues[0]} key={1}/>
                    <ChipEvidenceOrPrediction insight={props.insightsValues[1]} key={2}/>
                    <ChipEvidenceOrPrediction insight={props.insightsValues[2]} key={3}/>
                    <Chip label={"+" + (props.insightsValues.length - 3)}
                          size={"small"}
                          key={"more"}
                    />
                </>
                :
                props.insightsValues.map((insight: MaterialEntityAnnotation, idx: number) => <ChipEvidenceOrPrediction
                    insight={insight}
                    key={idx}/>)
            }
        </Stack>
    )
}

export function getInsightsColumns(ingredient: MaterialEntity) {
    const insights = filter_annotations_by_type_set(ingredient, annotationTypeSets.hybrid)
    return uniqueAnnotationsGroupedByType(insights);
}

// ----------------------------------------------------------------------
export function IngredientsContentsTable(props: {
    contents: any
}) {
    const theme = useTheme()

    const columns: MRT_ColumnDef<MaterialEntityCompositionRecord>[] = useMemo(
        () => {
            return [
                {
                    accessorKey: 'name',
                    header: 'Name',
                    columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
                    // @ts-ignore
                    Cell: ({row, renderedCellValue}) => <Stack direction={"row"} alignItems="center"
                                                               justifyContent="flex-start" spacing={1}>
                        {typeToIcon[row.original.type]}
                        <Link underline="hover" color="inherit" variant={"subtitle2"} component={RouterLink}
                              to={`/ingredients/${row.original.child_material_entity_id}`}>{renderedCellValue}</Link>
                    </Stack>,
                },
                {
                    accessorKey: 'total_amount',
                    header: 'Concentration',
                },
                // ...[...annotationTypeSets.hybrid].map((key) => ({
                //     accessorKey: annotationTypes[key],
                //     header: annotationTypes[key],
                //     columnFilterModeOptions: ['fuzzy'],
                //     size: 100,
                //     Cell: ({renderedCellValue}: any) => renderedCellValue ?
                //         <ChipStackEvidencePrediction insightsValues={renderedCellValue}/> : <></>
                // })),
            ]
        },
        [],
    );

    return <MaterialReactTable columns={columns}
                               data={props.contents}
                               enableColumnFilterModes
                               mrtTheme={mrtTheme(theme)}
    />
}

// ----------------------------------------------------------------------
export default function FoodContentSection() {
    let {foodId} = useParams();

    const {isLoading: isLoadingFoodData, data: foodProductData} = useQuery(
        foodMainFormulationDetailPageQuery(Number(foodId))
    )

    const formulationId = foodProductData?.id

    const {status: formulationStatus, data: formulationData} = useQuery(
        // @ts-ignore
        formulationContentPageQuery(formulationId)
    )

    function createData(
        name: string,
        calories: number,
        fat: number,
        carbs: number,
        protein: number,
    ) {
        return {name, calories, fat, carbs, protein};
    }

    return (
        <Grid2 container spacing={3}>
            <Grid2 size={12}>
                <Card>
                    <CardHeader
                        title={"What is the composition panel?"}
                        avatar={
                            <Info/>
                        }
                    />
                    <CardContent>
                        This panel includes all contents within a Food Product, it will calculate the presence of all
                        components of your choice of ingredients.
                    </CardContent>
                </Card>
            </Grid2>
            <Grid2 size={12}>
                <DataLoadingStatusHandler status={formulationStatus}
                                          successRender={
                                              <IngredientsContentsTable contents={formulationData?.composition}/>
                                          }
                                          loadingRender={
                                              <DataOperationStatus
                                                  icon={<CircularProgress/>}>
                                                  <Typography color={"textSecondary"}>
                                                      Analysing Product Composition.
                                                  </Typography>
                                              </DataOperationStatus>
                                          }
                />
            </Grid2>
        </Grid2>
    )
}