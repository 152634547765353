import {useState} from 'react';
// @mui
import {alpha} from '@mui/material/styles';
import {Avatar, Box, Button, IconButton, Link, Popover, Typography} from '@mui/material';
// @msal and authentication
import {useMsal} from "@azure/msal-react";
import LogoutButton from '../../../components/logout-button';
import {stringAvatar} from "../../../utils/userName";
import {fallBackUserName, feedbackForm} from "../../../config";

// ----------------------------------------------------------------------
export default function AccountPopover() {
	const [open, setOpen] = useState<any>(null);
	const {accounts} = useMsal();
	const account: any = accounts[0];

	const handleOpen = (event: any) => {
		setOpen(event.currentTarget);
	};

	const handleClose = () => {
		setOpen(null);
	};

	return (
		<>
			<Button component={Link} href={feedbackForm}>
				Feedback
			</Button>
			<IconButton
				onClick={handleOpen}
				sx={{
					p: 0,
					...(open && {
						'&:before': {
							zIndex: 1,
							content: "''",
							width: '100%',
							height: '100%',
							borderRadius: '50%',
							position: 'absolute',
							bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
						},
					}),
				}}
			>
				<Avatar {...stringAvatar(account.name ?? fallBackUserName)} />
			</IconButton>

			<Popover
				open={Boolean(open)}
				anchorEl={open}
				onClose={handleClose}
				anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
				transformOrigin={{vertical: 'top', horizontal: 'right'}}
				slotProps={{
					paper:{
						sx: {
							p: 0,
							mt: 1.5,
							ml: 0.75,
							width: 180,
							'& .MuiMenuItem-root': {
								typography: 'body2',
								borderRadius: 0.75,
							},
						},
					}}}
			>
				<Box sx={{my: 1.5, px: 2.5}}>
					<Typography variant="subtitle2" sx={{my: 1.5}} noWrap>
						{account.name}
					</Typography>
					<Typography variant="body2" sx={{color: 'textSecondary'}} noWrap>
						{account.username}
					</Typography>
					<LogoutButton fullWidth/>
				</Box>
			</Popover>
		</>
	);
}
